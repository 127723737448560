import { initializeApp } from 'firebase/app';
import { getFirestore } from 'firebase/firestore';
import { getStorage } from 'firebase/storage';
import { getAuth, onAuthStateChanged } from 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCMHHJ152wi4bzQ-yKc2_mhwXT14As0Ivg',
  authDomain: 'safelyshare-98b6c.firebaseapp.com',
  databaseURL:
    'https://safelyshare-98b6c-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'safelyshare-98b6c',
  storageBucket: 'safelyshare-98b6c.appspot.com',
  messagingSenderId: '281380846023',
  appId: '1:281380846023:web:cd6618808f6cfadbf081e2',
  measurementId: 'G-C6QC8EWHV8',
};

const app = initializeApp(firebaseConfig);
const storage = getStorage(app);
const db = getFirestore(app);
const auth = getAuth(app);

const getCurrentUserId = () => {
  return new Promise((resolve, reject) => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        resolve(user.uid);
      } else {
        reject(new Error('User not authenticated.'));
      }
      unsubscribe(); // Unsubscribe to avoid memory leaks
    });
  });
};

export { getCurrentUserId };

export const isAuthenticated = () => {
  let isAuthenticated = false;

  onAuthStateChanged(auth, (user) => {
    isAuthenticated = !!user;
  });

  return isAuthenticated;
};

export default app;
export { auth };
export { storage, db };
