import React, { useState } from 'react';
import './Styles/SideFilter.css';

function SideFilter() {
  const [rangeValues, setRangeValues] = useState([0, 10000]);
  const [checkedItems, setCheckedItems] = useState([]);
  const [minInput, setMinInput] = useState(0);
  const [maxInput, setMaxInput] = useState(10000);

  const handleRangeChange = (values) => {
    setRangeValues(values);
    setMinInput(values[0]);
    setMaxInput(values[1]);
  };

  const handleCheckboxChange = (value) => {
    const isChecked = checkedItems.includes(value);
    setCheckedItems((prevItems) =>
      isChecked
        ? prevItems.filter((item) => item !== value)
        : [...prevItems, value]
    );
  };

  const handleMinInputChange = (e) => {
    const minValue = Number(e.target.value);
    setMinInput(minValue);
    setRangeValues([minValue, rangeValues[1]]);
  };

  const handleMaxInputChange = (e) => {
    const maxValue = Number(e.target.value);
    setMaxInput(maxValue);
    setRangeValues([rangeValues[0], maxValue]);
  };

  const handleApplyFilter = () => {
    // Your filtering logic here using rangeValues and checkedItems
    console.log('Applying filter:', rangeValues, checkedItems);
  };

  return (
    <div className='side-navigation-bar'>
      {/* Range Filter */}
      <label htmlFor='range'>Range:</label>
      <input
        type='range'
        id='range'
        min='0'
        max='10000'
        step='1'
        value={rangeValues[0]}
        onChange={(e) =>
          handleRangeChange([Number(e.target.value), rangeValues[1]])
        }
      />
      <span className='range-values'>{rangeValues.join(' - ')}</span>

      {/* Input boxes for minimum and maximum values */}
      <div>
        <label htmlFor='minInput'>Min:</label>
        <input
          type='number'
          id='minInput'
          value={minInput}
          onChange={handleMinInputChange}
        />
        <label htmlFor='maxInput'>Max:</label>
        <input
          type='number'
          id='maxInput'
          value={maxInput}
          onChange={handleMaxInputChange}
        />
      </div>

      {/* Checklist */}
      <div>
        <h3>Filter Options</h3>
        {[
          'Marvel',
          'Car',
          'Bike',
          'Batman',
          'Jeep',
          'City',
          'Train',
          'Talking Tom',
          'Music',
        ].map((option) => (
          <div key={option}>
            <input
              type='checkbox'
              id={option}
              checked={checkedItems.includes(option)}
              onChange={() => handleCheckboxChange(option)}
            />
            <label htmlFor={option}>{option}</label>
          </div>
        ))}
      </div>

      {/* Apply button */}
      <button onClick={handleApplyFilter}>Apply</button>
    </div>
  );
}

export default SideFilter;
