import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './NavBar';
import {
  collection,
  getDocs,
  doc,
  query,
  where,
  getDoc,
  addDoc,
  updateDoc,
  writeBatch,
} from 'firebase/firestore';
import { db } from '../services/firebaseconig';
import './Styles/Shipping.css';
import { getAuth } from 'firebase/auth';

export default function Shipping() {
  const [cartItems, setCartItems] = useState([]);
  const [totalCost, setTotalCost] = useState(0);
  const [platformCost, setPlatformCost] = useState(2);
  const [insuranceCost, setInsuranceCost] = useState(0);
  const [loading, setLoading] = useState(true);
  const [durationCost, setDurationCost] = useState(0);
  const navigate = useNavigate();

  const [formData, setFormData] = useState({
    firstname: '',
    lastname: '',
    address: '',
    country: '',
    zipcode: '',
    city: '',
    phoneNumber:'',
    startDate: '',
    endDate: '',
  });

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const cartDocRef = collection(db, 'carts');
          const cartsSnapshot = await getDocs(
            query(cartDocRef, where('userId', '==', user.uid))
          );

          const cartItemsData = [];

          for (const cartDoc of cartsSnapshot.docs) {
            const cartData = cartDoc.data();
            const productDocRef = doc(db, 'products', cartData.productId);

            try {
              const productSnapshot = await getDoc(productDocRef);

              if (productSnapshot.exists()) {
                const productData = {
                  id: productSnapshot.id,
                  ...productSnapshot.data(),
                };

                const cartItem = {
                  id: cartDoc.id,
                  ...cartData,
                  ...productData,
                };

                cartItemsData.push(cartItem);
              } else {
                console.error(
                  `Product not found for productId: ${cartData.productId}`
                );
              }
            } catch (error) {
              console.error('Error fetching product:', error.message);
            }
          }

          setCartItems(cartItemsData);
        } else {
          console.log('User not logged in');
          setCartItems([]);
        }
      } catch (error) {
        console.error('Error fetching cart items:', error.message);
      }
    };

    fetchCartItems();
  }, []);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const userDocRef = doc(db, 'user', user.uid);
          const userSnapshot = await getDoc(userDocRef);

          if (userSnapshot.exists()) {
            const userData = userSnapshot.data();
            if (userData.address) {
              setFormData({
                ...formData,
                firstname: userData.address.firstname || '',
                lastname: userData.address.lastname || '',
                address: userData.address.address || '',
                country: userData.address.country || '',
                zipcode: userData.address.zipcode || '',
                city: userData.address.city || '',
                phoneNumber:userData.address.phoneNumber||'',
              });
            }
          } else {
            console.error('User not found');
          }
        } else {
          console.log('User not logged in');
        }
      } catch (error) {
        console.error('Error fetching user data:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchUserData();
  }, []);

  useEffect(() => {
    const calculateTotalCost = () => {
      const platformCost = 2;
      setPlatformCost(platformCost);

      const insurancePercentage = 0.1;
      const insuranceCost = cartItems.reduce(
        (total, item) => total + (item.ProductPrice || 0) * insurancePercentage,
        0
      );
      setInsuranceCost(insuranceCost);

      const durationCost = cartItems.reduce((total, item) => {
        const productPrice = item.ProductPrice || 0;
        const startDate = new Date(formData.startDate);
        const endDate = new Date(formData.endDate);
        const oneWeekInMilliseconds = 7 * 24 * 60 * 60 * 1000;

        const durationInWeeks = Math.ceil(
          (endDate - startDate) / oneWeekInMilliseconds
        );

        return total + productPrice * durationInWeeks;
      }, 0);

      setDurationCost(durationCost);

      const productCost = cartItems.reduce(
        (total, item) => total + (item.ProductPrice || 0),
        0
      );

      const totalCost =
        productCost + platformCost + insuranceCost + durationCost;
      setTotalCost(totalCost.toFixed(2));
    };

    calculateTotalCost();
  }, [cartItems, formData.startDate, formData.endDate]);

  const handleContinue = async (e) => {
    e.preventDefault();
    const auth = getAuth();
    const user = auth.currentUser;

    for (const field in formData) {
      if (formData[field].trim() === '') {
        toast.error(`Please fill in ${field} field.`, {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
        });
        return;
      }
    }

    const currentDate = new Date();
    const startDate = new Date(formData.startDate);
    const endDate = new Date(formData.endDate);

    if (startDate <= currentDate || endDate <= currentDate) {
      toast.error('Please select future dates for start and end.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (startDate.getTime() === endDate.getTime()) {
      toast.error('Start date and end date cannot be the same.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    toast.warning(
      'Please double-check your shipping details before continuing.',
      {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );

    try {
      const orderRef = await addDoc(collection(db, 'orders'), {
        userId: user.uid,
        shippingDetails: formData,
        productDetails: cartItems,
        startDate: formData.startDate,
        endDate: formData.endDate,
        totalCost: parseFloat(totalCost),
        timestamp: new Date(),
      });

      const batch = writeBatch(db);

      cartItems.forEach((cartItem) => {
        const productDocRef = doc(db, 'products', cartItem.id);
        const currentDate = new Date();
        const endDate = new Date(formData.endDate);
        const newAvailability = currentDate > endDate ? true : false;
        batch.update(productDocRef, { availability: newAvailability });
      });

      const userDocRef = doc(db, 'user', user.uid);
      batch.update(userDocRef, {
        address: {
          firstname: formData.firstname,
          lastname: formData.lastname,
          address: formData.address,
          country: formData.country,
          zipcode: formData.zipcode,
          city: formData.city,
          phoneNumber:formData.phoneNumber,
        },
      });

      await batch.commit();
      console.log('Order added with ID: ', orderRef.id);
    } catch (error) {
      console.error('Error adding order to Firestore:', error);
      toast.error('Error placing the order. Please try again later.', {
        position: 'top-center',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
    setTimeout(() => {
      navigate('/OrderSuccess');
    }, 5000);
  };

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />

      <div className='outerbox'>
        <div className='cont-s'>
          <br />
          <img className='sbg' alt='Elementfooter' src='./images/sbg.png' />

          <div className='container-sh'>
            <div className='form'>
              <h1>Shipping Details</h1>
              <div className='fields fields--2'>
                <label className='fields' htmlFor='firstname'>
                  <span className='field__label--2'>First name</span>
                  <input
                    className='field__inputs'
                    type='text'
                    id='firstname'
                    name='firstname'
                    value={formData.firstname}
                    onChange={handleInputChange}
                    placeholder='Firstname'
                    required
                  />
                </label>
                <label className='fields' htmlFor='lastname'>
                  <span className='field__label--2'>Last name</span>
                  <input
                    className='field__inputs'
                    type='text'
                    id='lastname'
                    name='lastname'
                    value={formData.lastname}
                    onChange={handleInputChange}
                    placeholder='Lastname'
                    required
                  />
                </label>
              </div>
              <label className='fields' htmlFor='address'>
                <span className='field__label--2'>Address</span>
                <input
                  className='field__inputs'
                  type='text'
                  id='address'
                  name='address'
                  value={formData.address}
                  onChange={handleInputChange}
                  placeholder='Address'
                  required
                />
              </label>
              <label className='fields' htmlFor='country'>
                <span className='field__label--2'>Country</span>
                <input
                  className='field__inputs'
                  type='text'
                  id='country'
                  name='country'
                  value={formData.country}
                  onChange={handleInputChange}
                  placeholder='Country'
                  required
                />
              </label>
              <div className='fields fields--3'>
                <label className='field' s htmlFor='zipcode'>
                  <span className='field__label--2'>Zip code</span>
                  <input
                    className='field__inputs'
                    type='text'
                    id='zipcode'
                    name='zipcode'
                    value={formData.zipcode}
                    onChange={handleInputChange}
                    placeholder='ZipCode'
                    required
                  />
                </label>
                <label className='field' htmlFor='startDate'>
                  <span className='field__label--2'>Start Date</span>
                  <input
                    className='field__inputs'
                    type='date'
                    id='startDate'
                    name='startDate'
                    value={formData.startDate}
                    onChange={handleInputChange}
                    required
                  />
                </label>
                <label className='field' htmlFor='endDate'>
                  <span className='field__label--2'>End Date</span>
                  <input
                    className='field__inputs'
                    type='date'
                    id='endDate'
                    name='endDate'
                    value={formData.endDate}
                    onChange={handleInputChange}
                    required
                  />
                </label>
              </div>
              <div class='fields fields--2'>
                <label className='fields' htmlFor='city'>
                <span className='field__label--2'> City</span>
                <input
                  className='field__inputs'
                  type='text'
                  id='city'
                  name='city'
                  value={formData.city}
                  onChange={handleInputChange}
                  placeholder='City'
                  required
                />
                </label>
                <label className='fields' htmlFor='phoneNumber'>
                  <span className='field__label--2'>PhoneNumber</span>
                  <input
                    className='field__inputs'
                    type='text'
                    id='phoneNumber'
                    name='phoneNumber'
                    value={formData.phoneNumber}
                    onChange={handleInputChange}
                    placeholder='phoneNumber'
                    required
                  />
                </label>
              </div>

              <div className='button-container'>
                <button
                  type='submit'
                  className='button'
                  onClick={handleContinue}
                >
                  Continue
                </button>
              </div>
              <br />
            </div>
            <ToastContainer />
          </div>
          <div className='vertical-space-container'></div>

          <div className='container1'>
            <div className='product-details'>
              <img
                className=' side_cont'
                src='./images/Group 145.png'
                alt='Product Details'
                style={{ position: 'relative', width: '100%', height: 'auto' }}
              />
              <div className='content-container'>
                <div className='head'>
                  <h4>Product Details</h4>
                </div>

                {cartItems.map((item) => (
                  <div key={item.id} className='cart-item33'>
                    <img src={item.ProductImg} alt={item.ProductName} />
                    <div>
                      <p>{item.ProductName}</p>
                      <p>${item.ProductPrice.toFixed(2)}</p>
                    </div>
                  </div>
                ))}

                <div className='payment-label'>
                  <div className='label-text'>Platform Cost</div>
                  <div className='label-value'>${platformCost.toFixed(2)}</div>
                </div>
                <div className='payment-label'>
                  <div className='label-text'>Insurance Cost</div>
                  <div className='label-value'>${insuranceCost.toFixed(2)}</div>
                </div>
                <div className='payment-label'>
                  <div className='label-text'>Duration Cost</div>
                  <div className='label-value'>${durationCost.toFixed(2)}</div>
                </div>
                <div className='payment-total'>
                  <div className='total-text'>Total</div>
                  <div className='total-value'>${totalCost}</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
