import './Styles/Contactus.css';

export default function ContactUs() {
  const inputs = document.querySelectorAll(".input");

  function focusFunc() {
    let parent = this.parentNode;
    parent.classList.add("focus");
  }

  function blurFunc() {
    let parent = this.parentNode;
    if (this.value === "") {
      parent.classList.remove("focus");
    }
  }

  inputs.forEach((input) => {
    input.addEventListener("focus", focusFunc);
    input.addEventListener("blur", blurFunc);
  });

  return (
    <div>
      <div className="containerqq">

        <div className="formqq">
          <div className="contact-infoqq">
            <h3 className="titleqq">Let's get in touch</h3>
            <p className="textqq">
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Saepe
              dolorum adipisci recusandae praesentium dicta!
            </p>

            <div className="social-mediaqq">
              <p>Connect with us :</p>
             
                </div>
          </div>

          <div className="contact-formqq">
            <span className="circle one"></span>
            <span className="circle two"></span>

            <form action="index.html" autoComplete="off">
              <h3 className="title">Contact us</h3>
              <div className="input-container">
                <input type="text" name="name" className="input" />
                <label htmlFor="">Name</label>
               
              </div>
              <div className="input-container">
                <input type="email" name="email" className="input" />
                <label htmlFor="">Email</label>
               
              </div>
              
              <div className="input-container textarea">
                <textarea name="message" className="input"></textarea>
                <label htmlFor="">Your Question</label>
               
              </div>
              <input type="submit" value="Send" className="btn" />
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
