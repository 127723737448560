import { getUserData, removeUserData } from './storage';

let logoutTimeout;

const resetLogoutTimeout = () => {
  clearTimeout(logoutTimeout);
  logoutTimeout = setTimeout(logout, 100);
};

const isAuthenticated = () => {
  return getUserData() !== null;
};

const logout = () => {
  removeUserData();
  clearTimeout(logoutTimeout);
};

const handleWindowClose = () => {
  logout();
};

resetLogoutTimeout();

window.addEventListener('beforeunload', handleWindowClose);

export { isAuthenticated, logout };
