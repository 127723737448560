import React, { useEffect, useState } from 'react';
import { useNavigate, Navigate, Link } from 'react-router-dom';
import { getDocs, query, collection, where } from 'firebase/firestore';
import { auth, db } from '../services/firebaseconig';
import { logout, isAuthenticated } from '../services/Auth';
import NavBar from './NavBar';
import MetaData from './MetaDatas';
import Footer from './Footer';
import './Styles/dashboard.css';
import TopBox from './TopBox';
import Box1 from './Box1';

export default function DashboardPage() {
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userProducts, setUserProducts] = useState([]);
  const [userOrders, setUserOrders] = useState([]);
  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged(async (authUser) => {
      if (authUser) {
        // Split display name to extract first name
        const displayName = authUser.displayName;
        const firstName = displayName ? displayName.split(' ')[0] : '';

        setUser({
          firstName: firstName,
          name: authUser.displayName,
          email: authUser.email,
          uid: authUser.uid,
        });

        // Fetch user products
        await fetchUserProducts(authUser.uid);

        // Fetch user orders
        await fetchUserOrders(authUser.uid);
      } else {
        setUser(null);
        setUserProducts([]);
        setUserOrders([]);
      }
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  const fetchUserProducts = async (userUID) => {
    try {
      const productsSnapshot = await getDocs(
        query(collection(db, 'products'), where('UserId', '==', userUID))
      );

      const productsData = [];
      productsSnapshot.forEach((doc) => {
        productsData.push({ id: doc.id, ...doc.data() });
      });

      setUserProducts(productsData);
    } catch (error) {
      console.error('Error fetching user products:', error.message);
    }
  };

  const fetchUserOrders = async (userUID) => {
    try {
      const ordersSnapshot = await getDocs(
        query(collection(db, 'order'), where('userId', '==', userUID))
      );

      const ordersData = ordersSnapshot.docs.map((doc) => {
        const orderData = doc.data();
        console.log('Order Data:', orderData); // Log the order data
        const productDetails = orderData.productDetails || [];

        // Map over productDetails array to get required fields
        const productDetailsData = productDetails.map((product) => ({
          productId: product.productId,
          productName: product.productName,
          productImage: product.productImage,
          endDate: product.endDate || 'N/A',
        }));

        return {
          docId: doc.id,
          products: productDetailsData,
        };
      });

      console.log('User Orders:', ordersData); // Log the user orders
      setUserOrders(ordersData);
    } catch (error) {
      console.error('Error fetching user orders:', error.message);
    }
  };

  const handleViewDetails = (order) => {
    navigate('/more-detail', { state: { orderDetails: order } });
  };

  const tostore = () => {
    navigate('/product');
  };

  const logoutUser = () => {
    logout();
    navigate('/login');
  };

  if (!isAuthenticated()) {
    return <Navigate to='/login' />;
  }

  return (
    <div>
      <NavBar logoutUser={logoutUser} />
      <MetaData title={'Dashboard'} />
      <div className='all'>
        <div className='dash'>My Dashboard</div>
        <div className='underline3'></div>

        <div className='user-info-container'>
          <div className='small-boxes-containerq'>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
            <Box1></Box1>
          </div>

          <div className='cust-idoutline'>
            <div className='small-boxes-containerq'>
              <TopBox></TopBox>
              <TopBox></TopBox>
              <TopBox></TopBox>
            </div>

            <div className='cust-id'>
              {user ? (
                <div>
                  <h5 className='wel' id='ww'>
                    {' '}
                    Welcome {user.firstName}
                  </h5>

                  <br></br>
                </div>
              ) : (
                <p>Loading...</p>
              )}
            </div>
          </div>
        </div>

        <div className='dashboard-container'>
          <div className='column'>
            <div className='small-boxes-containerq'>
              <div className='smallbox1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.5 2C2.5822 4.08767 3.18991 5.75266 3.85804 6.84935C4.39703 7.73406 5.14411 8.95642 5.77042 9.32003C6.25463 9.60114 6.69059 10.0728 7.15037 10.5291C7.84715 11.2206 8.55905 11.4565 9.28006 11.9353C10.4901 12.7388 11.6216 13.6156 12.8549 14.1957C14.0298 14.7483 15.1406 15.5163 16.3428 15.8253C17.4492 16.1096 18.4928 16.5795 19.5808 16.9555C20.0167 17.1061 20.4141 17.6428 20.8521 17.7965C21.2192 17.9254 21.6014 17.8342 21.9713 17.8491C22.566 17.8731 3.91856 16.6429 4.5 16.9555C6.191 17.8645 8.42705 18.295 10.1122 17.0212C11.165 16.2253 31.3985 17.8491 32.5 17.8491'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.32422'
                    y1='19.7391'
                    x2='34.7483'
                    y2='19.7391'
                    stroke='#C6923B'
                    stroke-width='4'
                  />
                  <line
                    x1='1.73046'
                    y1='2.99357'
                    x2='1.79513'
                    y2='21.7387'
                    stroke='#C6923B'
                    stroke-width='2'
                  />
                  <path
                    d='M5.20087 13.0355C4.87338 12.4913 4.13001 11.0191 4.51194 10.0218C4.91288 8.97483 5.90212 11.8635 6.05542 12.4032C6.28871 13.2244 5.81289 13.1457 5.50559 12.948C4.86169 12.5339 4.30798 11.4072 4.0681 10.1563C3.92714 9.42127 3.73766 8.54344 3.45866 7.9768C3.41807 7.89435 2.81379 6.7211 3.05789 7.06193C3.30838 7.41169 3.4416 8.034 3.40567 8.63604C3.30552 10.314 3.29305 11.9452 3.29305 13.6409C3.29305 14.0276 3.31304 17.7631 3.6872 16.9371C3.99488 16.2579 4.16719 15.3945 4.39932 14.6231C4.63485 13.8403 5.27667 16.172 5.35323 16.5201C5.49548 17.1666 5.26413 16.5567 5.09488 16.3586C5.06739 16.3264 4.82365 16.1633 4.90278 16.1972C5.87885 16.6145 6.93048 16.361 7.91355 16.2375C8.94448 16.1081 9.99565 15.8567 11.0303 15.9685C11.5428 16.0238 12.9165 17.0243 11.4609 15.7263C10.9774 15.2951 9.4714 13.973 8.99664 13.5602C8.73875 13.336 8.48067 13.1126 8.22159 12.8942C8.12322 12.8113 8.01906 12.5986 7.92018 12.679C7.90317 12.6928 8.36953 13.2468 8.40044 13.2777C8.84295 13.7191 9.34097 14.0028 9.75844 14.5423C9.8836 14.7041 9.46062 14.5284 9.31461 14.4751C7.9597 13.9806 6.61452 13.378 5.26049 12.874C5.00152 12.7777 4.73379 12.6722 4.46888 12.6722C4.05417 12.6722 5.27922 13.0314 5.68445 13.2104C6.93076 13.7609 8.17361 14.3226 9.41397 14.9258C9.76556 15.0967 10.1624 15.123 10.5004 15.3899C10.8867 15.6951 10.282 15.4215 10.1923 15.3765C9.70371 15.1313 9.20351 14.8934 8.69854 14.8518C8.28229 14.8175 8.88306 14.9772 8.95689 15.0132C10.6386 15.8335 12.2464 17.1524 13.9947 17.1524C14.1196 17.1524 13.745 17.1426 13.6205 17.1255C12.9906 17.0388 12.3645 16.8174 11.7524 16.5066C11.6508 16.455 11.3495 16.3048 11.4543 16.3048C12.1001 16.3048 12.7425 16.7177 13.3753 16.9237C14.6644 17.3433 15.973 17.2735 17.2738 17.2735C17.6602 17.2735 18.0381 17.5885 18.4264 17.63C18.7374 17.6633 19.0523 17.6367 19.3638 17.6367C19.9373 17.6367 20.4297 18 21 18'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.5 2C2.5822 4.08767 3.18991 5.75266 3.85804 6.84935C4.39703 7.73406 5.14411 8.95642 5.77042 9.32003C6.25463 9.60114 6.69059 10.0728 7.15037 10.5291C7.84715 11.2206 8.55905 11.4565 9.28006 11.9353C10.4901 12.7388 11.6216 13.6156 12.8549 14.1957C14.0298 14.7483 15.1406 15.5163 16.3428 15.8253C17.4492 16.1096 18.4928 16.5795 19.5808 16.9555C20.0167 17.1061 20.4141 17.6428 20.8521 17.7965C21.2192 17.9254 21.6014 17.8342 21.9713 17.8491C22.566 17.8731 3.91856 16.6429 4.5 16.9555C6.191 17.8645 8.42705 18.295 10.1122 17.0212C11.165 16.2253 31.3985 17.8491 32.5 17.8491'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.32422'
                    y1='19.7391'
                    x2='34.7483'
                    y2='19.7391'
                    stroke='#C6923B'
                    stroke-width='4'
                  />
                  <line
                    x1='1.73046'
                    y1='2.99357'
                    x2='1.79513'
                    y2='21.7387'
                    stroke='#C6923B'
                    stroke-width='2'
                  />
                  <path
                    d='M5.20087 13.0355C4.87338 12.4913 4.13001 11.0191 4.51194 10.0218C4.91288 8.97483 5.90212 11.8635 6.05542 12.4032C6.28871 13.2244 5.81289 13.1457 5.50559 12.948C4.86169 12.5339 4.30798 11.4072 4.0681 10.1563C3.92714 9.42127 3.73766 8.54344 3.45866 7.9768C3.41807 7.89435 2.81379 6.7211 3.05789 7.06193C3.30838 7.41169 3.4416 8.034 3.40567 8.63604C3.30552 10.314 3.29305 11.9452 3.29305 13.6409C3.29305 14.0276 3.31304 17.7631 3.6872 16.9371C3.99488 16.2579 4.16719 15.3945 4.39932 14.6231C4.63485 13.8403 5.27667 16.172 5.35323 16.5201C5.49548 17.1666 5.26413 16.5567 5.09488 16.3586C5.06739 16.3264 4.82365 16.1633 4.90278 16.1972C5.87885 16.6145 6.93048 16.361 7.91355 16.2375C8.94448 16.1081 9.99565 15.8567 11.0303 15.9685C11.5428 16.0238 12.9165 17.0243 11.4609 15.7263C10.9774 15.2951 9.4714 13.973 8.99664 13.5602C8.73875 13.336 8.48067 13.1126 8.22159 12.8942C8.12322 12.8113 8.01906 12.5986 7.92018 12.679C7.90317 12.6928 8.36953 13.2468 8.40044 13.2777C8.84295 13.7191 9.34097 14.0028 9.75844 14.5423C9.8836 14.7041 9.46062 14.5284 9.31461 14.4751C7.9597 13.9806 6.61452 13.378 5.26049 12.874C5.00152 12.7777 4.73379 12.6722 4.46888 12.6722C4.05417 12.6722 5.27922 13.0314 5.68445 13.2104C6.93076 13.7609 8.17361 14.3226 9.41397 14.9258C9.76556 15.0967 10.1624 15.123 10.5004 15.3899C10.8867 15.6951 10.282 15.4215 10.1923 15.3765C9.70371 15.1313 9.20351 14.8934 8.69854 14.8518C8.28229 14.8175 8.88306 14.9772 8.95689 15.0132C10.6386 15.8335 12.2464 17.1524 13.9947 17.1524C14.1196 17.1524 13.745 17.1426 13.6205 17.1255C12.9906 17.0388 12.3645 16.8174 11.7524 16.5066C11.6508 16.455 11.3495 16.3048 11.4543 16.3048C12.1001 16.3048 12.7425 16.7177 13.3753 16.9237C14.6644 17.3433 15.973 17.2735 17.2738 17.2735C17.6602 17.2735 18.0381 17.5885 18.4264 17.63C18.7374 17.6633 19.0523 17.6367 19.3638 17.6367C19.9373 17.6367 20.4297 18 21 18'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.5 2C2.5822 4.08767 3.18991 5.75266 3.85804 6.84935C4.39703 7.73406 5.14411 8.95642 5.77042 9.32003C6.25463 9.60114 6.69059 10.0728 7.15037 10.5291C7.84715 11.2206 8.55905 11.4565 9.28006 11.9353C10.4901 12.7388 11.6216 13.6156 12.8549 14.1957C14.0298 14.7483 15.1406 15.5163 16.3428 15.8253C17.4492 16.1096 18.4928 16.5795 19.5808 16.9555C20.0167 17.1061 20.4141 17.6428 20.8521 17.7965C21.2192 17.9254 21.6014 17.8342 21.9713 17.8491C22.566 17.8731 3.91856 16.6429 4.5 16.9555C6.191 17.8645 8.42705 18.295 10.1122 17.0212C11.165 16.2253 31.3985 17.8491 32.5 17.8491'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.32422'
                    y1='19.7391'
                    x2='34.7483'
                    y2='19.7391'
                    stroke='#C6923B'
                    stroke-width='4'
                  />
                  <line
                    x1='1.73046'
                    y1='2.99357'
                    x2='1.79513'
                    y2='21.7387'
                    stroke='#C6923B'
                    stroke-width='2'
                  />
                  <path
                    d='M5.20087 13.0355C4.87338 12.4913 4.13001 11.0191 4.51194 10.0218C4.91288 8.97483 5.90212 11.8635 6.05542 12.4032C6.28871 13.2244 5.81289 13.1457 5.50559 12.948C4.86169 12.5339 4.30798 11.4072 4.0681 10.1563C3.92714 9.42127 3.73766 8.54344 3.45866 7.9768C3.41807 7.89435 2.81379 6.7211 3.05789 7.06193C3.30838 7.41169 3.4416 8.034 3.40567 8.63604C3.30552 10.314 3.29305 11.9452 3.29305 13.6409C3.29305 14.0276 3.31304 17.7631 3.6872 16.9371C3.99488 16.2579 4.16719 15.3945 4.39932 14.6231C4.63485 13.8403 5.27667 16.172 5.35323 16.5201C5.49548 17.1666 5.26413 16.5567 5.09488 16.3586C5.06739 16.3264 4.82365 16.1633 4.90278 16.1972C5.87885 16.6145 6.93048 16.361 7.91355 16.2375C8.94448 16.1081 9.99565 15.8567 11.0303 15.9685C11.5428 16.0238 12.9165 17.0243 11.4609 15.7263C10.9774 15.2951 9.4714 13.973 8.99664 13.5602C8.73875 13.336 8.48067 13.1126 8.22159 12.8942C8.12322 12.8113 8.01906 12.5986 7.92018 12.679C7.90317 12.6928 8.36953 13.2468 8.40044 13.2777C8.84295 13.7191 9.34097 14.0028 9.75844 14.5423C9.8836 14.7041 9.46062 14.5284 9.31461 14.4751C7.9597 13.9806 6.61452 13.378 5.26049 12.874C5.00152 12.7777 4.73379 12.6722 4.46888 12.6722C4.05417 12.6722 5.27922 13.0314 5.68445 13.2104C6.93076 13.7609 8.17361 14.3226 9.41397 14.9258C9.76556 15.0967 10.1624 15.123 10.5004 15.3899C10.8867 15.6951 10.282 15.4215 10.1923 15.3765C9.70371 15.1313 9.20351 14.8934 8.69854 14.8518C8.28229 14.8175 8.88306 14.9772 8.95689 15.0132C10.6386 15.8335 12.2464 17.1524 13.9947 17.1524C14.1196 17.1524 13.745 17.1426 13.6205 17.1255C12.9906 17.0388 12.3645 16.8174 11.7524 16.5066C11.6508 16.455 11.3495 16.3048 11.4543 16.3048C12.1001 16.3048 12.7425 16.7177 13.3753 16.9237C14.6644 17.3433 15.973 17.2735 17.2738 17.2735C17.6602 17.2735 18.0381 17.5885 18.4264 17.63C18.7374 17.6633 19.0523 17.6367 19.3638 17.6367C19.9373 17.6367 20.4297 18 21 18'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.5 2C2.5822 4.08767 3.18991 5.75266 3.85804 6.84935C4.39703 7.73406 5.14411 8.95642 5.77042 9.32003C6.25463 9.60114 6.69059 10.0728 7.15037 10.5291C7.84715 11.2206 8.55905 11.4565 9.28006 11.9353C10.4901 12.7388 11.6216 13.6156 12.8549 14.1957C14.0298 14.7483 15.1406 15.5163 16.3428 15.8253C17.4492 16.1096 18.4928 16.5795 19.5808 16.9555C20.0167 17.1061 20.4141 17.6428 20.8521 17.7965C21.2192 17.9254 21.6014 17.8342 21.9713 17.8491C22.566 17.8731 3.91856 16.6429 4.5 16.9555C6.191 17.8645 8.42705 18.295 10.1122 17.0212C11.165 16.2253 31.3985 17.8491 32.5 17.8491'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.32422'
                    y1='19.7391'
                    x2='34.7483'
                    y2='19.7391'
                    stroke='#C6923B'
                    stroke-width='4'
                  />
                  <line
                    x1='1.73046'
                    y1='2.99357'
                    x2='1.79513'
                    y2='21.7387'
                    stroke='#C6923B'
                    stroke-width='2'
                  />
                  <path
                    d='M5.20087 13.0355C4.87338 12.4913 4.13001 11.0191 4.51194 10.0218C4.91288 8.97483 5.90212 11.8635 6.05542 12.4032C6.28871 13.2244 5.81289 13.1457 5.50559 12.948C4.86169 12.5339 4.30798 11.4072 4.0681 10.1563C3.92714 9.42127 3.73766 8.54344 3.45866 7.9768C3.41807 7.89435 2.81379 6.7211 3.05789 7.06193C3.30838 7.41169 3.4416 8.034 3.40567 8.63604C3.30552 10.314 3.29305 11.9452 3.29305 13.6409C3.29305 14.0276 3.31304 17.7631 3.6872 16.9371C3.99488 16.2579 4.16719 15.3945 4.39932 14.6231C4.63485 13.8403 5.27667 16.172 5.35323 16.5201C5.49548 17.1666 5.26413 16.5567 5.09488 16.3586C5.06739 16.3264 4.82365 16.1633 4.90278 16.1972C5.87885 16.6145 6.93048 16.361 7.91355 16.2375C8.94448 16.1081 9.99565 15.8567 11.0303 15.9685C11.5428 16.0238 12.9165 17.0243 11.4609 15.7263C10.9774 15.2951 9.4714 13.973 8.99664 13.5602C8.73875 13.336 8.48067 13.1126 8.22159 12.8942C8.12322 12.8113 8.01906 12.5986 7.92018 12.679C7.90317 12.6928 8.36953 13.2468 8.40044 13.2777C8.84295 13.7191 9.34097 14.0028 9.75844 14.5423C9.8836 14.7041 9.46062 14.5284 9.31461 14.4751C7.9597 13.9806 6.61452 13.378 5.26049 12.874C5.00152 12.7777 4.73379 12.6722 4.46888 12.6722C4.05417 12.6722 5.27922 13.0314 5.68445 13.2104C6.93076 13.7609 8.17361 14.3226 9.41397 14.9258C9.76556 15.0967 10.1624 15.123 10.5004 15.3899C10.8867 15.6951 10.282 15.4215 10.1923 15.3765C9.70371 15.1313 9.20351 14.8934 8.69854 14.8518C8.28229 14.8175 8.88306 14.9772 8.95689 15.0132C10.6386 15.8335 12.2464 17.1524 13.9947 17.1524C14.1196 17.1524 13.745 17.1426 13.6205 17.1255C12.9906 17.0388 12.3645 16.8174 11.7524 16.5066C11.6508 16.455 11.3495 16.3048 11.4543 16.3048C12.1001 16.3048 12.7425 16.7177 13.3753 16.9237C14.6644 17.3433 15.973 17.2735 17.2738 17.2735C17.6602 17.2735 18.0381 17.5885 18.4264 17.63C18.7374 17.6633 19.0523 17.6367 19.3638 17.6367C19.9373 17.6367 20.4297 18 21 18'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox1'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.5 2C2.5822 4.08767 3.18991 5.75266 3.85804 6.84935C4.39703 7.73406 5.14411 8.95642 5.77042 9.32003C6.25463 9.60114 6.69059 10.0728 7.15037 10.5291C7.84715 11.2206 8.55905 11.4565 9.28006 11.9353C10.4901 12.7388 11.6216 13.6156 12.8549 14.1957C14.0298 14.7483 15.1406 15.5163 16.3428 15.8253C17.4492 16.1096 18.4928 16.5795 19.5808 16.9555C20.0167 17.1061 20.4141 17.6428 20.8521 17.7965C21.2192 17.9254 21.6014 17.8342 21.9713 17.8491C22.566 17.8731 3.91856 16.6429 4.5 16.9555C6.191 17.8645 8.42705 18.295 10.1122 17.0212C11.165 16.2253 31.3985 17.8491 32.5 17.8491'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.32422'
                    y1='19.7391'
                    x2='34.7483'
                    y2='19.7391'
                    stroke='#C6923B'
                    stroke-width='4'
                  />
                  <line
                    x1='1.73046'
                    y1='2.99357'
                    x2='1.79513'
                    y2='21.7387'
                    stroke='#C6923B'
                    stroke-width='2'
                  />
                  <path
                    d='M5.20087 13.0355C4.87338 12.4913 4.13001 11.0191 4.51194 10.0218C4.91288 8.97483 5.90212 11.8635 6.05542 12.4032C6.28871 13.2244 5.81289 13.1457 5.50559 12.948C4.86169 12.5339 4.30798 11.4072 4.0681 10.1563C3.92714 9.42127 3.73766 8.54344 3.45866 7.9768C3.41807 7.89435 2.81379 6.7211 3.05789 7.06193C3.30838 7.41169 3.4416 8.034 3.40567 8.63604C3.30552 10.314 3.29305 11.9452 3.29305 13.6409C3.29305 14.0276 3.31304 17.7631 3.6872 16.9371C3.99488 16.2579 4.16719 15.3945 4.39932 14.6231C4.63485 13.8403 5.27667 16.172 5.35323 16.5201C5.49548 17.1666 5.26413 16.5567 5.09488 16.3586C5.06739 16.3264 4.82365 16.1633 4.90278 16.1972C5.87885 16.6145 6.93048 16.361 7.91355 16.2375C8.94448 16.1081 9.99565 15.8567 11.0303 15.9685C11.5428 16.0238 12.9165 17.0243 11.4609 15.7263C10.9774 15.2951 9.4714 13.973 8.99664 13.5602C8.73875 13.336 8.48067 13.1126 8.22159 12.8942C8.12322 12.8113 8.01906 12.5986 7.92018 12.679C7.90317 12.6928 8.36953 13.2468 8.40044 13.2777C8.84295 13.7191 9.34097 14.0028 9.75844 14.5423C9.8836 14.7041 9.46062 14.5284 9.31461 14.4751C7.9597 13.9806 6.61452 13.378 5.26049 12.874C5.00152 12.7777 4.73379 12.6722 4.46888 12.6722C4.05417 12.6722 5.27922 13.0314 5.68445 13.2104C6.93076 13.7609 8.17361 14.3226 9.41397 14.9258C9.76556 15.0967 10.1624 15.123 10.5004 15.3899C10.8867 15.6951 10.282 15.4215 10.1923 15.3765C9.70371 15.1313 9.20351 14.8934 8.69854 14.8518C8.28229 14.8175 8.88306 14.9772 8.95689 15.0132C10.6386 15.8335 12.2464 17.1524 13.9947 17.1524C14.1196 17.1524 13.745 17.1426 13.6205 17.1255C12.9906 17.0388 12.3645 16.8174 11.7524 16.5066C11.6508 16.455 11.3495 16.3048 11.4543 16.3048C12.1001 16.3048 12.7425 16.7177 13.3753 16.9237C14.6644 17.3433 15.973 17.2735 17.2738 17.2735C17.6602 17.2735 18.0381 17.5885 18.4264 17.63C18.7374 17.6633 19.0523 17.6367 19.3638 17.6367C19.9373 17.6367 20.4297 18 21 18'
                    stroke='#C6923B'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
            </div>

            <div className='lab'>
              <div className='textalign'>MY PRODUCTS</div>
              <div
                className='products-lent'
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                {userProducts.length > 0 ? (
                  userProducts.map((product) => (
                    <div key={product.id} className='product-item123'>
                      <div className='product-container'>
                        {/* Wrap the img tag inside the Link component */}
                        <Link
                          to={`/ProductDetails/${product.id}`}
                          className='product-link1'
                        >
                          <img
                            src={product.ProductImg}
                            alt={`Product: ${product.ProductName}`}
                            className='product-image'
                          />
                        </Link>

                        <div className='product-details23'>
                          <div className='product-name'>
                            Product Name: {product.ProductName}
                          </div>
                          {/* Add more product details as needed */}
                        </div>
                      </div>
                    </div>
                  ))
                ) : (
                  <p>No products added yet.</p>
                )}

                <Link className='addp1' to='/Productadd'>
                  +Add More Products
                </Link>
              </div>
              <br />
              <br />
              <Link className='addp1' to='/Productadd'>
                +Add More Products
              </Link>
            </div>
          </div>

          <div className='column'>
            <div className='small-boxes-containerq'>
              <div className='smallbox2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#033F60'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#033F60'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#033F60'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#033F60'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#033F60'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#033F60'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#033F60'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#033F60'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox2'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#033F60'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#033F60'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#033F60'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
            </div>
            <div
              className='earnings'
              style={{
                maxHeight: '300px',
                overflowY: 'auto',
                scrollbarWidth: 'none',
                msOverflowStyle: 'none',
              }}
            >
              <div className='textalignq'>PRODUCTS LENT</div>

              {userProducts.length > 0 ? (
                userProducts
                  .filter((product) => !product.availability)
                  .map((product) => (
                    <div key={product.id} className='product-item123'>
                      <div className='lego'>
                        <div className='product-name-container'>
                          <h5>Product Name: {product.ProductName}</h5>
                          <Link
                            id='linkfont'
                            to={`/ProductDetails/${product.id}`}
                          >
                            click here for product details
                          </Link>
                        </div>
                      </div>
                    </div>
                  ))
              ) : (
                <p>No products lent yet.</p>
              )}
            </div>

            <div className='borrow-now'>
              <div className='textalign'>
                WANT TO BORROW A PRODUCT?
                <br />
                <br />
                <button id='show' onClick={tostore}>
                  {' '}
                  CLICK HERE{' '}
                </button>
              </div>
            </div>
          </div>

          <div className='column'>
            <div className='small-boxes-containerq'>
              <div className='smallbox31'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#689A9E'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#689A9E'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox31'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#689A9E'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#689A9E'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox31'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#689A9E'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#689A9E'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox31'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#689A9E'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#689A9E'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
              <div className='smallbox31'>
                <svg
                  xmlns='http://www.w3.org/2000/svg'
                  width='35'
                  height='22'
                  viewBox='0 0 35 22'
                  fill='none'
                >
                  <path
                    d='M2.76953 2C2.85165 4.08767 3.45877 5.75266 4.12624 6.84935C4.6647 7.73406 5.41104 8.95642 6.03674 9.32003C6.52047 9.60114 6.95601 10.0728 7.41534 10.5291C8.11144 11.2206 8.82263 11.4565 9.54294 11.9353C10.7518 12.7388 11.8822 13.6156 13.1143 14.1957C14.288 14.7483 15.3977 15.5163 16.5987 15.8253C17.7041 16.1096 18.7467 16.5795 19.8336 16.9555C20.269 17.1061 20.6661 17.6428 21.1036 17.7965C21.4704 17.9254 21.8522 17.8342 22.2217 17.8491C22.8158 17.8731 4.1867 16.6429 4.76757 16.9555C6.45691 17.8645 8.69077 18.295 10.3742 17.0212C11.426 16.2253 31.6397 17.8491 32.7401 17.8491'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                  <line
                    x1='2.59375'
                    y1='19.7391'
                    x2='34.9861'
                    y2='19.7391'
                    stroke='#689A9E'
                    stroke-width='4'
                  />
                  <line
                    x1='1.99999'
                    y1='2.99357'
                    x2='2.06466'
                    y2='21.7387'
                    stroke='#689A9E'
                    stroke-width='2'
                  />
                  <path
                    d='M5.46434 13.0355C5.13717 12.4913 4.39453 11.0191 4.77608 10.0218C5.17663 8.97483 6.1649 11.8635 6.31804 12.4032C6.5511 13.2244 6.07575 13.1457 5.76876 12.948C5.12549 12.5339 4.57232 11.4072 4.33268 10.1563C4.19186 9.42127 4.00256 8.54344 3.72384 7.9768C3.68328 7.89435 3.0796 6.7211 3.32346 7.06193C3.5737 7.41169 3.70679 8.034 3.67089 8.63604C3.57085 10.314 3.55839 11.9452 3.55839 13.6409C3.55839 14.0276 3.57836 17.7631 3.95215 16.9371C4.25952 16.2579 4.43167 15.3945 4.66357 14.6231C4.89887 13.8403 5.54006 16.172 5.61655 16.5201C5.75865 17.1666 5.52754 16.5567 5.35845 16.3586C5.33099 16.3264 5.08749 16.1633 5.16653 16.1972C6.14165 16.6145 7.19225 16.361 8.17436 16.2375C9.20427 16.1081 10.2544 15.8567 11.2881 15.9685C11.8001 16.0238 13.1724 17.0243 11.7182 15.7263C11.2352 15.2951 9.73067 13.973 9.25638 13.5602C8.99875 13.336 8.74092 13.1126 8.48209 12.8942C8.38382 12.8113 8.27976 12.5986 8.18097 12.679C8.16398 12.6928 8.62989 13.2468 8.66077 13.2777C9.10284 13.7191 9.60038 14.0028 10.0174 14.5423C10.1425 14.7041 9.71991 14.5284 9.57404 14.4751C8.22046 13.9806 6.8766 13.378 5.5239 12.874C5.26518 12.7777 4.99772 12.6722 4.73306 12.6722C4.31876 12.6722 5.54261 13.0314 5.94744 13.2104C7.19253 13.7609 8.43416 14.3226 9.6733 14.9258C10.0245 15.0967 10.421 15.123 10.7586 15.3899C11.1446 15.6951 10.5405 15.4215 10.4509 15.3765C9.96276 15.1313 9.46305 14.8934 8.95857 14.8518C8.54274 14.8175 9.14292 14.9772 9.21667 15.0132C10.8967 15.8335 12.5029 17.1524 14.2496 17.1524C14.3743 17.1524 14.0001 17.1426 13.8757 17.1255C13.2465 17.0388 12.6209 16.8174 12.0094 16.5066C11.9079 16.455 11.607 16.3048 11.7116 16.3048C12.3568 16.3048 12.9986 16.7177 13.6308 16.9237C14.9186 17.3433 16.2259 17.2735 17.5254 17.2735C17.9114 17.2735 18.289 17.5885 18.6769 17.63C18.9876 17.6633 19.3022 17.6367 19.6133 17.6367C20.1863 17.6367 20.6782 18 21.248 18'
                    stroke='#689A9E'
                    stroke-width='4'
                    stroke-linecap='round'
                  />
                </svg>
              </div>
            </div>

            <div className='lab1'>
              <div className='textalign'> PRODUCTS BORROWED</div>
              <div
                className='products-lent'
                style={{
                  maxHeight: '300px',
                  overflowY: 'auto',
                  scrollbarWidth: 'none',
                  msOverflowStyle: 'none',
                }}
              >
                {userOrders.length > 0 ? (
                  userOrders.map((order) => (
                    <div key={order.docId}>
                      {order.products.map((product) => (
                        <div
                          key={product.productId}
                          className='product-item123'
                        >
                          <div className='lego'>
                            Product Name: {product.ProductName}{' '}
                            {/* Use correct case */}
                            <br />
                            Order ID: {order.docId}
                          </div>
                          <Link to={`/ProductDetails/${product.productId}`}>
                            click here for product details
                          </Link>
                        </div>
                      ))}
                    </div>
                  ))
                ) : (
                  <p>No products borrowed yet.</p>
                )}
              </div>

              <br />
              <br />

              <Link className='addp2' to='/product'>
                +Add More Products
              </Link>
            </div>
          </div>
        </div>

        <br></br>
        <Footer />
      </div>
    </div>
  );
}
