import React, { useState, useEffect } from 'react';
import {
  collection,
  getDocs,
  query,
  where,
  orderBy,
  startAt,
  endAt,
} from 'firebase/firestore';
import { db } from '../services/firebaseconig';
import { useNavigate } from 'react-router-dom';
import NavBar from './NavBar';
import Footer from './Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout, isAuthenticated } from '../services/Auth';
import SideFilter from './SideFilter';
import MetaData from './MetaDatas';
import Snav from './Snav';

import './Styles/product.css';

const Product = () => {
  const [products, setProducts] = useState([]);
  const [filteredProducts, setFilteredProducts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [minValue, setMinValue] = useState(0); // Default min value
  const [maxValue, setMaxValue] = useState(10000); // Default max value
  const [searchQuery, setSearchQuery] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const fetchProducts = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const q = query(productsCollection, orderBy('ProductName'));
        const productsSnapshot = await getDocs(q);

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        setProducts(productsData);
        setLoading(false);
      } catch (error) {
        console.error('Error fetching products:', error.message);
        setLoading(false);
      }
    };

    fetchProducts();
  }, []);

  useEffect(() => {
    const filtered = products.filter((product) => {
      const price =
        typeof product.ProductPrice === 'number' ? product.ProductPrice : 0;
      return (
        price >= minValue &&
        price <= maxValue &&
        product.ProductName.toLowerCase().includes(searchQuery.toLowerCase())
      );
    });

    setFilteredProducts(filtered);
  }, [products, minValue, maxValue, searchQuery]);

  const handleViewDetails = (productId) => {
    navigate(`/ProductDetails/${productId}`);
  };


  return (
    <div className='all'>
      <NavBar />
      <MetaData title={'Store'} />

      <div className='Protop'>
        Product Catalogue
        <div className='Protopline'></div>
        <div className='searchbox123'>
          <img src='./images/Group 225.png' alt='Search Image' />
          <input
            type='text'
            placeholder='Search'
            className='search123-input'
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
          />
        </div>
      </div>
      <br></br>
      <br></br>

      {loading ? (
        <div className='loader-container'>
          <div className='loader'></div>
        </div>
      ) : (
        <div className='product-page1'>
          <Snav setMinValue={setMinValue} setMaxValue={setMaxValue} />

          <div className='product-list'>
            {filteredProducts.map((product) => (
              <div key={product.id} className='product-item'>
                <div className='product-image-container'>
                  <img
                    src={product.ProductImg}
                    alt={product.ProductName}
                    className='product-list-image'
                  />
                </div>
                <div className='product-info'>
                  <h3>{product.ProductName}</h3>
                  <p className='product-price'>Kr.{product.ProductPrice}</p>
                  <p>{product.Description}</p>
                  
                  {/*<p
                    className={`availability ${
                      product.availability ? 'available' : 'unavailable'
                    }`}
                  >
                    Availability:{' '}
                    {product.availability ? 'In Stock' : 'Out of Stock'}
                  </p>*/}
                  <div className='product-buttons'>

                    <button onClick={() => handleViewDetails(product.id)}>View Details</button>

                  </div>
                </div>
              </div>
            ))}
          </div>
        </div>
      )}

      <Footer />
    </div>
  );
};

export default Product;
