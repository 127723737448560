import React from 'react';

const MoreDetails = ({ location }) => {
  // Check if location is defined and has a state property
  if (!location || !location.state) {
    // Handle the case where location or location.state is undefined
    // You might want to navigate the user back to a previous page or handle it accordingly
    return <p>Error: Unable to retrieve order details.</p>;
  }

  const { orderDetails } = location.state;

  return (
    <div>
      <h2>More Details</h2>
      <p>Order ID: {orderDetails.docId}</p>
      <ul>
        {orderDetails.products.map((product) => (
          <li key={product.productName}>
            <div>
              <div>
                {product.productName} - {product.endDate}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </div>
  );
};

export default MoreDetails;
