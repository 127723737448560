import React from 'react';
import { createRoot } from 'react-dom';
import './index.css';
import App from './App';
import { CartProvider } from './services/CartContext';
import reportWebVitals from './reportWebVitals';

const root = createRoot(document.getElementById('root'));

root.render(
  <CartProvider>
    <App />
  </CartProvider>
);

reportWebVitals();
