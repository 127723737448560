import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import Landingpage from './components/Landingpages';
import SignUp from './components/SignUp';
import SignIn from './components/SignIn';
import Passwordresets from './components/Passwordresets';
import Verificationpassword from './components/Verificationpassword';
import Newpassword from './components/Newpassword';
import DashboardPage from './components/dashboard';
import Profile from './components/Profile';
import ProductList from './components/ProductList';
import Subnavbar from './components/Subnavbar';
import SideNav from './components/SideNav';
import ProductDetails from './components/ProductDetails';
import AddProducts from './components/Addproduct';
import ChildSignup from './components/ChildSignup';
import PricingCard from './components/PricingCard';
import PricingApp from './components/PricingApp';
import NewDashboard from './components/NewDashboard';
import Product from './components/Product';
import Shipping from './components/Shipping';
import Cart from './components/Cart';
import Payment from './components/Payment';
import OrderSuccess from './components/OrderSuccess';
import MoreDetails from './components/productsborrowed';
import NotFound from './components/NotFound';
import Contactus from './components/Contactus';
import { Welcome } from './components/Welcome';
import Snav from './components/Snav';
import Landpage from './components/Landpage';

function App() {
  return (
    <div className='App'>
      <HelmetProvider>
        <BrowserRouter>
          <Routes>
            <Route path='/register' element={<SignUp />} />
            <Route path='/childregister' element={<ChildSignup />} />
            <Route path='/login' element={<SignIn />} />
            <Route path='/dashboard' element={<DashboardPage />} />
            <Route path='/ResetPassword' element={<Newpassword />} />
            <Route path='/ForgotPassword' element={<Passwordresets />} />
            <Route path='/Profile' element={<Profile />} />
            <Route path='/SubNav' element={<Subnavbar />} />
            <Route path='/Productadd' element={<AddProducts />} />
            <Route path='/' element={<Landpage />} />
            <Route path='/ProductList' element={<ProductList />} />
            <Route path='/product' element={<Product />} />
            <Route path='/Verification' element={<Verificationpassword />} />
            <Route path='/SideNav' element={<SideNav />} />
            <Route path='/ProductDetails/:id' element={<ProductDetails />} />
            <Route path='/PricingCard' element={<PricingCard />} />
            <Route path='/PricingApp' element={<PricingApp />} />
            <Route path='/NewDashboard' element={<NewDashboard />} />
            <Route path='/cart' element={<Cart />} />
            <Route path='/Shipping-Detials' element={<Shipping />} />
            <Route path='/Payment' element={<Payment />} />
            <Route path='/Contactus' element={<Contactus />} />
            <Route path='/Welcome' element={<Welcome />} />
            <Route path='/OrderSuccess' element={<OrderSuccess />} />
            <Route path='/more-detail' element={<MoreDetails />} />
            <Route path='*' element={<NotFound />} />
            <Route path='Welcome' element={<Landingpage />} />
          </Routes>
        </BrowserRouter>
      </HelmetProvider>
    </div>
  );
}

export default App;
