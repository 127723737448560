// CartContext.js

import React, { createContext, useContext, useReducer, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebaseconig';
import { isAuthenticated } from '../services/Auth';

const CartContext = createContext();

const cartReducer = (state, action) => {
  switch (action.type) {
    case 'ADD_TO_CART':
      return {
        ...state,
        cartItems: [...state.cartItems, action.payload],
      };
    case 'REMOVE_FROM_CART':
      return {
        ...state,
        cartItems: state.cartItems.filter((item) => item.id !== action.payload),
      };
    case 'LOAD_CART':
      return {
        ...state,
        cartItems: action.payload,
      };
    default:
      return state;
  }
};

const CartProvider = ({ children }) => {
  const [cartState, dispatch] = useReducer(cartReducer, { cartItems: [] });
  const { currentUser } = isAuthenticated();

  useEffect(() => {
    const loadCartData = async () => {
      if (currentUser) {
        try {
          const cartDocRef = doc(db, 'carts', currentUser.uid);
          const cartDocSnap = await getDoc(cartDocRef);

          if (cartDocSnap.exists()) {
            dispatch({
              type: 'LOAD_CART',
              payload: cartDocSnap.data().cartItems,
            });
          }
        } catch (error) {
          console.error('Error loading cart data:', error.message);
        }
      }
    };

    if (currentUser) {
      loadCartData();
    }
  }, [currentUser, dispatch]);

  useEffect(() => {
    let isMounted = true;

    const saveCartData = async () => {
      if (currentUser && isMounted) {
        try {
          const cartDocRef = doc(db, 'carts', currentUser.uid);
          const cartItemsData = cartState.cartItems.map((item) => ({
            id: item.id,
            // Include other relevant item properties
          }));

          await setDoc(cartDocRef, { cartItems: cartItemsData });
        } catch (error) {
          console.error('Error saving cart data:', error.message);
        }
      } else {
        console.warn('User not authenticated or component unmounted!');
      }
    };

    saveCartData();

    return () => {
      isMounted = false;
    };
  }, [currentUser, cartState.cartItems]);

  const handleAddToCart = async (product) => {
    if (!currentUser) {
      return;
    }
  
    try {
      const cartDocRef = doc(db, 'carts', currentUser.uid);
      const cartDocSnap = await getDoc(cartDocRef);
  
      const cartItems = cartDocSnap.exists() ? cartDocSnap.data().cartItems : [];
  
      // Check if the product is already in the cart
      if (cartItems.some((item) => item.productId === product.productId)) {
        console.log('Item already in the cart');
        return;
      }
  
      // Add the entire product document to cartItems with the document ID
      const updatedCartItems = [...cartItems, { ...product, documentId: product.id }];
      await setDoc(cartDocRef, { cartItems: updatedCartItems });
  
      dispatch({
        type: 'ADD_TO_CART',
        payload: { ...product, documentId: product.id }, // Include document ID in the payload
      });
  
      console.log('Item added to the cart');
    } catch (error) {
      console.error('Error adding item to cart:', error.message);
    }
  };
  
  

  return (
    <CartContext.Provider value={{ cartState, dispatch, handleAddToCart }}>
      {children}
    </CartContext.Provider>
  );
};

const useCart = () => {
  const context = useContext(CartContext);
  if (!context) {
    throw new Error('useCart must be used within a CartProvider');
  }
  return context;
};

export { CartProvider, useCart };
