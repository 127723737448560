import "./Styles/signin.css";
import NavBar from "./NavBar";
import React, { useState } from "react";
import OTPInput from "react-otp-input";

export default function Verificationpassword() {
  const [otp, setOtp] = useState("");

  return (
    <div>
      <NavBar />
      <section className="login-block">
        <div className="container">
          <OTPInput
            value={otp}
            onChange={setOtp}
            numInputs={6}
            renderSeparator={<span></span>}
            renderInput={(props) => <input {...props} />}
          />
        </div>
      </section>
    </div>
  );
}
