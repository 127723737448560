import './Styles/signin.css';
import NavBar from './NavBar';
import { useState } from 'react';
import { LoginApi } from '../services/api';
import { storeUserData } from '../services/storage';
import { isAuthenticated } from '../services/Auth';
import { Link, Navigate } from 'react-router-dom';

export default function Passwordresets() {
  const initialStateErrors = {
    email: { required: false },
    custom_error: null,
  };
  const [errors, setErrors] = useState(initialStateErrors);

  const [loading, setLoading] = useState(false);

  const [inputs, setInputs] = useState({
    email: '',
  });
  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  const handleSubmit = (event) => {
    console.log(inputs);
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;

    if (inputs.email == '') {
      errors.email.required = true;
      hasError = true;
    }
    if (!hasError) {
      setLoading(true);
      LoginApi(inputs)
        .then((response) => {
          storeUserData(response.data.idToken);
        })
        .catch((err) => {
          if ((err.code = 'ERR_BAD_REQUEST')) {
            setErrors({
              ...errors,
              custom_error: 'Oops You got something wrong.',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setErrors({ ...errors });
  };

  return (
    <div>
      <NavBar />
      <section className='login-block'>
        <div className='container'>
          <div className='row '>
            <div className='col login-sec'>
              <h2 className='text-center'>Forgot Password</h2>
              <form onSubmit={handleSubmit} className='login-form' action=''>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Enter your Email address
                  </label>
                  <input
                    type='email'
                    className='form-control'
                    onChange={handleInput}
                    name='email'
                    id=''
                    placeholder='email'
                  />
                  {errors.email.required ? (
                    <span className='text-danger'>Email is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  {loading ? (
                    <div className='text-center'>
                      <div
                        className='spinner-border text-primary '
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : null}
                  <span className='text-danger'>
                    {errors.custom_error ? <p>{errors.custom_error}</p> : null}
                  </span>
                  <input
                    type='submit'
                    className='btn btn-login float-right'
                    disabled={loading}
                    value='Continue'
                  />
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
