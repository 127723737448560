import React, { useEffect, useState } from 'react';
import { getAuth } from 'firebase/auth';
import { doc, getDoc, updateDoc } from 'firebase/firestore';
import { logout, isAuthenticated } from '../services/Auth';
import { db } from '../services/firebaseconig';
import NavBar from '../components/NavBar';
import MetaData from './MetaDatas';
import './Styles/profile.css';

export default function Profile() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const [editing, setEditing] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    number: '',
    address: '',
  });

  useEffect(() => {
    const fetchUserDetails = async () => {
      try {
        if (isAuthenticated()) {
          const auth = getAuth();
          const currentUser = auth.currentUser;

          if (currentUser) {
            const userDocRef = doc(db, 'user', currentUser.uid);
            const userSnapshot = await getDoc(userDocRef);

            if (userSnapshot.exists()) {
              const data = userSnapshot.data();
              setUser({
                name: `${data.address.firstname}`,
                email: currentUser.email,
                number: data.address.phoneNumber || 'Not provided',
                address: `${data.address.address}, ${data.address.city}, ${data.address.country}, ${data.address.zipcode}`,
              });
              setFormData({
                name: `${data.address.firstname}`,
                email: currentUser.email || '',
                number: data.address.phoneNumber || '',
                address: `${data.address.address}, ${data.address.city}, ${data.address.country}, ${data.address.zipcode}`,
              });
            }
          }
        }
      } catch (error) {
        console.error('Error fetching user details:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchUserDetails();
  }, []);

  const logoutUser = () => {
    logout();
  };

  const handleEditToggle = () => {
    setEditing(!editing);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const auth = getAuth();
      const currentUser = auth.currentUser;

      if (currentUser) {
        const userDocRef = doc(db, 'user', currentUser.uid);
        await updateDoc(userDocRef, {
          address: {
            firstname: formData.name.split(' ')[0],
            phoneNumber: formData.number,
            address: formData.address.split(', ')[0],
            city: formData.address.split(', ')[1],
            country: formData.address.split(', ')[2],
            zipcode: formData.address.split(', ')[3],
          },
        });
        setUser(formData);
        setEditing(false);
      }
    } catch (error) {
      console.error('Error updating user details:', error);
    }
  };

  if (!isAuthenticated()) {
    return <p>Not authenticated</p>;
  }

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <div>
      <NavBar logoutUser={logoutUser} />
      <MetaData title={'Profile'} />
      <div className='container-profile'>
        <div className='container-profile-2'>
          <div className='profile-icon'>
            <img src='./images/profileicon.png' alt='Profile Icon' />
          </div>
          {editing ? (
            <form onSubmit={handleSubmit}>
              <div className='profile-row'>
                <div className='profile-title'>Name</div>
                <input
                  type='text'
                  name='name'
                  value={formData.name}
                  onChange={handleChange}
                  className='profile-data'
                />
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Email</div>
                <input
                  type='email'
                  name='email'
                  value={formData.email}
                  onChange={handleChange}
                  className='profile-data'
                />
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Phone</div>
                <input
                  type='text'
                  name='number'
                  value={formData.number}
                  onChange={handleChange}
                  className='profile-data'
                />
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Address</div>
                <input
                  type='text'
                  name='address'
                  value={formData.address}
                  onChange={handleChange}
                  className='profile-data'
                />
              </div>
              <button type='submit' className='profile-edit-button'>
                Save
              </button>
            </form>
          ) : (
            <>
              <div className='profile-row'>
                <div className='profile-title'>Name</div>
                <div className='profile-data'>{user.name}</div>
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Email</div>
                <div className='profile-data'>{user.email}</div>
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Phone</div>
                <div className='profile-data'>{user.number}</div>
              </div>
              <div className='profile-row'>
                <div className='profile-title'>Address</div>
                <div className='profile-data'>{user.address}</div>
              </div>
              <button onClick={handleEditToggle} className='profile-edit-button'>
                Edit
              </button>
            </>
          )}
        </div>
      </div>
    </div>
  );
}
