import React, { useState } from 'react';
import './Styles/SignUp.css';
import Footer from './Footer';
import { doc, setDoc } from 'firebase/firestore';
import { db } from '../services/firebaseconig';
import MetaData from './MetaDatas';
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from '../components/NavBar';
import { isAuthenticated } from '../services/firebaseconig';
import { storeUserData } from '../services/storage';
import { Link, Navigate, useNavigate } from 'react-router-dom';
import {
  getAuth,
  createUserWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  sendEmailVerification,
  OAuthProvider,
} from 'firebase/auth';

function SignUp() {
  const navigate = useNavigate();
  const auth = getAuth();

  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    name: { required: false },
    lastname: { required: false },
    dob: { required: false },
    ssn: { required: false },
    address1: { required: false },
    address2: { required: false },
    postal: { required: false },
    state: { required: false },
    country: { required: false },
    confirmpassword: { required: false, mismatch: false },
    custom_error: null,
  };

  const [errors, setErrors] = useState(initialStateErrors);
  const [loading, setLoading] = useState(false);

  const handleGoogleSignup = async () => {
    try {
      const provider = new GoogleAuthProvider();
      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const uid = user.uid;

      // Split display name into first name and last name
      const displayNameParts = user.displayName.split(' ');
      const firstName = displayNameParts[0];
      const lastName = displayNameParts.slice(1).join(' ');

      const userDocRef = doc(db, 'user', uid);
      await setDoc(
        userDocRef,
        {
          uid: uid,
          firstName: firstName,
          lastName: lastName,
          email: user.email,
        },
        { merge: true }
      );

      navigate('/login');
      await sendEmailVerification(auth.currentUser);
    } catch (error) {
      console.error('Google authentication error:', error);
    }
  };
  const handleMicrosoftSignIn = async () => {
    setLoading(true);

    try {
      const provider = new OAuthProvider('microsoft.com');

      const result = await signInWithPopup(auth, provider);

      const user = result.user;
      const uid = user.uid;
      const displayNameParts = user.displayName.split(' ');
      const firstName = displayNameParts[0];
      const lastName = displayNameParts.slice(1).join(' ');

      const userDocRef = doc(db, 'user', uid);
      await setDoc(
        userDocRef,
        {
          uid: uid,
          firstName: firstName,
          lastName: lastName,
          email: user.email,
        },
        { merge: true }
      );
      navigate('/login');
      await sendEmailVerification(auth.currentUser);
    } catch (error) {
      console.error('Microsoft authentication error:', error);
      // Handle error as needed
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    let hasError = false;
    const newErrors = { ...initialStateErrors };

    const validateField = (fieldName, errorMessage) => {
      if (!inputs[fieldName]) {
        newErrors[fieldName].required = true;
        hasError = true;
      }
    };

    validateField('name', 'First Name is required.');
    validateField('lastname', 'Last Name is required.');
    validateField('email', 'Email is required.');
    validateField('password', 'Password is required.');
    validateField('ssn', 'SSN is required.');
    validateField('dob', 'DOB is required.');
    validateField('address1', 'Address Line 1 is required.');
    validateField('address2', 'Address Line 2 is required.');
    validateField('country', 'Country is required.');
    validateField('state', 'State is required.');
    validateField('postal', 'Postal Code is required.');

    if (!hasError) {
      const handleRegistration = async () => {
        setLoading(true);

        try {
          const authResult = await createUserWithEmailAndPassword(
            auth,
            inputs.email,
            inputs.password
          );

          await sendEmailVerification(auth.currentUser);

          const uid = authResult.user.uid;

          storeUserData(authResult.user.accessToken);

          const userDocRef = doc(db, 'user', uid);
          await setDoc(
            userDocRef,
            {
              uid: uid,
              name: inputs.name,
              email: inputs.email,
              lastname: inputs.lastname,
              dob: inputs.dob,
              ssn: inputs.ssn,
              address1: inputs.address1,
              address2: inputs.address2,
              postal: inputs.postal,
              state: inputs.state,
              country: inputs.country,
            },
            { merge: true }
          );

          navigate('/login');
        } catch (error) {
          if (error.code === 'auth/email-already-in-use') {
            newErrors.custom_error = 'This email has already been registered!';
          } else if (error.code === 'auth/weak-password') {
            newErrors.custom_error =
              'Password should be at least 6 characters!';
          } else {
            newErrors.custom_error = 'An unexpected error occurred.';
          }
        } finally {
          setLoading(false);
        }
      };

      await handleRegistration();
    }

    setErrors(newErrors);
  };

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    name: '',
    lastname: '',
    dob: '',
    ssn: '',
    address1: '',
    address2: '',
    postal: '',
    state: '',
    country: '',
    confirmpassword: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(
        (prevShowConfirmPassword) => !prevShowConfirmPassword
      );
    }
  };

  const handleInput = (event) => {
    const { name, value } = event.target;

    if (['ssn', 'postal'].includes(name)) {
      // Check if the value contains only digits and limit the length
      const sanitizedValue = value
        .replace(/\D/g, '')
        .slice(0, name === 'ssn' ? 12 : 6);

      // Update the input value and errors
      setInputs((prevInputs) => ({ ...prevInputs, [name]: sanitizedValue }));
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: {
          required: sanitizedValue === '',
          invalid: sanitizedValue.length !== (name === 'ssn' ? 12 : 6),
        },
      }));
    } else {
      // For other fields, update normally
      setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));
    }

    setInputs((prevInputs) => ({ ...prevInputs, [name]: value }));

    if (name === 'confirmpassword') {
      setErrors((prevErrors) => ({
        ...prevErrors,
        confirmpassword: {
          required: inputs.password !== value,
          mismatch: false,
        },
      }));
    }
  };

  if (isAuthenticated()) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='signup'>
      <NavBar />
      <MetaData title={'SignUp'} />
      <div className='Title'>
        <br></br>
        Sign Up now and become part of our huge community <br></br>
        
      </div>
      <br></br>
      <img className='signtop' alt='Elementfooter' src='./images/signtop.png' />
      <div className='register-block'>
        <div
          className='container'
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)' }}
        >
          <div className='row '>
            <div className='col register-sec'>
              <h10 className='text-center-signup'>SIGN UP</h10>
              <br></br>

              <h4 className='rectagles'></h4>

              <form onSubmit={handleSubmit} className='register-form' action=''>
                {/*<div className='form-groupname'>
                  <label htmlFor='firstName' className='text-uppercase-login'>
                    First Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='name'
                    id='firstName'
                    placeholder='Steve'
                  />
                  {errors.name.required === true ? (
                    <span className='text-danger'>First Name is required.</span>
                  ) : null}
                </div>
                <div className='form-groupname'>
                  <label htmlFor='lastName' className='text-uppercase-login'>
                    Last Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='lastname'
                    id='lastName'
                    placeholder='Roger'
                  />
                  {errors.lastname.required === true ? (
                    <span className='text-danger'>Last Name is required.</span>
                  ) : null}
                </div>
                <div className='form-groupdob'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    Date of Birth
                  </label>

                  <input
                    type='date'
                    className='form-control'
                    onChange={handleInput}
                    name='dob'
                    id=''
                  />
                  {errors.dob.required === true ? (
                    <span className='text-danger'>DOB is required.</span>
                  ) : null}
                </div>
                <div className='form-group-ssn'>
                  <label htmlFor='ssn' className='text-uppercase-login'>
                    Social Security Number
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='ssn'
                    id='ssn'
                    placeholder='SSN'
                  />
                  {errors.ssn.required === true ? (
                    <span className='text-danger'>SSN is required.</span>
                  ) : null}
                  {errors.ssn.invalid === true ? (
                    <span className='text-danger'>SSN must be exactly 12 digits.</span>
                  ) : null}
                </div>

                <div className='form-groupemail'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    Email
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='email'
                    id=''
                    placeholder='abc@safelyshare.com'
                  />
                  {errors.email.required === true ? (
                    <span className='text-danger'>Email is required.</span>
                  ) : null}
                </div>
                <div className='form-groupaddress1'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    Address Line 1
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='address1'
                    id=''
                    placeholder='NO:23,Geenstreet'
                  />
                  {errors.address1.required === true ? (
                    <span className='text-danger'>
                      Address Line 1 is required.
                    </span>
                  ) : null}
                </div>
                <div className='form-groupaddress2'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    Address Line 2
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='address2'
                    id=''
                    placeholder='VIP Road'
                  />
                  {errors.address2.required === true ? (
                    <span className='text-danger'>
                      Address Line 2 is required.
                    </span>
                  ) : null}
                </div>
                <div className='form-group-postal'>
                  <label htmlFor='postal' className='text-uppercase-login'>
                    Postal Code
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='postal'
                    id='postal'
                    placeholder='Postal'
                    min="0"
                    max="9999999"
                  />
                  {errors.postal.required === true ? (
                    <span className='text-danger'>Postal Code is required.</span>
                  ) : null}
                  {errors.postal.invalid === true ? (
                    <span className='text-danger'>Postal Code must be exactly 6 digits.</span>
                  ) : null}
                </div>

                <div className='form-groupstate'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    State
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='state'
                    id=''
                    placeholder='Tamil Nadu'
                  />
                  {errors.state.required === true ? (
                    <span className='text-danger'>state is required.</span>
                  ) : null}
                </div>
                <div className='form-groupcountry'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase-login'
                  >
                    Country
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='country'
                    id=''
                    placeholder='India'
                  />
                  {errors.country.required === true ? (
                    <span className='text-danger'>Country is required.</span>
                  ) : null}
                </div>
                <div className='form-grouppassword'>
                  <label
                    htmlFor='exampleInputPassword1'
                    className='text-uppercase-login'
                  >
                    Password
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleInput}
                      name='password'
                      id=''
                      placeholder='******'
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        onClick={() => togglePasswordVisibility('password')}
                      >
                        <i
                          className={`fas ${showPassword ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                        />
                      </button>
                    </div>
                  </div>
                  {errors.password.required && (
                    <span className='text-danger'>Password is required.</span>
                  )}
                </div>
                <div className='form-groupcopassword'>
                  <label
                    htmlFor='exampleInputPassword1'
                    className='text-uppercase-login'
                  >
                    Confirm Password
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={handleInput}
                      name='confirmpassword'
                      id=''
                      placeholder='******'
                    />
                    <br></br>
                    <div className='input-group-append'>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        onClick={() =>
                          togglePasswordVisibility('confirmPassword')
                        }
                      >
                        <i
                          className={`fas ${showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                            }`}
                        />
                      </button>
                    </div>
                  </div>
                  {errors.confirmpassword.required && (
                    <span className='text-danger'>
                      Password mismatching. Please enter the same password.
                    </span>
                  )}
                </div>
                <div className='form-group'>
                  <span className='text-danger'>
                    {errors.custom_error === true ? (
                      <p>{errors.custom_error}</p>
                    ) : null}
                  </span>
                  {loading ? (
                    <div className='text-center'>
                      <div
                        className='spinner-border text-primary '
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  <input
                    type='submit'
                    className='btn-login'
                    disabled={loading}
                    value='Register'
                  />
                </div>*/}
                <br></br>
                <div onClick={handleGoogleSignup} className='btnsgoogle'>
                  <img src='./images/googlesignup.png' alt='Google Sign In' />{' '}
                </div>
                <br></br>
                <div onClick={handleMicrosoftSignIn} className='btngoogle'>
                  <img src='./images/btn-mic.png' alt='Google Sign In' />
                </div>
                <br></br>
                <div className='clearfix'></div>
                <div className='last-msg'>
                  Already have an account ? Please{' '}
                  <Link to='/login'>Login</Link>
                </div>
                <br></br>
              </form>
            </div>
          </div>
        </div>
      </div>
      <br />
      <img
        className='elementfooter'
        alt='Elementfooter'
        src='./images/signinfooter.png'
      />
    </div>
  );
}

export default SignUp;
