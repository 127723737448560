import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './NavBar';
import Footer from './Footer';
import MetaData from './MetaDatas';
import './Styles/landing.css';

export default function Landingpage() {
  useEffect(() => {
    // Display a welcome toast message when the component mounts
    toast.info(
      'Welcome to SafelyShare! We are curently in the testing and we would be happy for your feedback',
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }, []); // Empty dependency array ensures the effect runs only once on mount

  return (
    <div>
      <NavBar />
      <MetaData title={'Safelyshare- A Beginning'} />
      <div className='sign-up-welcome-page'>
        <div className='overlap-wrapper'>
          <div className='overlap'>
            <div className='overlap-group'>
              <div className='cloud'>
                <div className='div-h'>
                  <div className='rectangle' />
                  <div className='ellipse' />
                  <div className='ellipse-2' />
                  <div className='ellipse-3' />
                  <div className='ellipse-4' />
                  <div className='ellipse-5' />
                </div>
              </div>
              <div className='overlap-group-wrapper'>
                <div className='div-h'>
                  <div className='rectangle-2' />
                  <div className='ellipse-6' />
                  <div className='ellipse-7' />
                  <div className='ellipse-8' />
                  <div className='ellipse-9' />
                  <div className='ellipse-10' />
                </div>
              </div>
              <div className='field'>
                <div className='text-wrapper'>Welcome to SafelyShare</div>
              </div>
              <p className='p'>
                Get Started by setting up your product in few clicks. After
                product setup , you can start lending your product and earn
                passive income.
              </p>
              <p className='text-wrapper-2'>Click here to begin your journey</p>
              <img
                className='group'
                alt='Group'
                src='https://c.animaapp.com/PgE7NZzK/img/group-28.png'
              />
              <img
                className='line'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-27.svg'
              />
              <img
                className='img'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-32.svg'
              />
              <img
                className='line-2'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-28.svg'
              />
              <img
                className='line-3'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-29.svg'
              />
              <img
                className='line-4'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-30.svg'
              />
              <img
                className='line-5'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-31.svg'
              />
              <img
                className='line-6'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-33.svg'
              />
              <img
                className='line-7'
                alt='Line'
                src='https://c.animaapp.com/PgE7NZzK/img/line-34.svg'
              />
            </div>
            <img
              className='arrow'
              alt='Arrow'
              src='https://c.animaapp.com/PgE7NZzK/img/arrow-2.svg'
            />
            <img
              className='rectangle-4'
              alt='Rectangle'
              src='./images/thor.png'
            />
            <li className='going-top'>
              <Link className='going' to='/register'>
                <img
                  style={{ width: '250px', height: '250px' }}
                  src='./images/land.png'
                  alt='Logo'
                />
              </Link>
            </li>
          </div>
        </div>
      </div>
      <br />
      <Footer />
      <ToastContainer />
    </div>
  );
}
