import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faFacebookF,
  faInstagram,
  faLinkedinIn,
} from '@fortawesome/free-brands-svg-icons';
import { faEnvelope } from '@fortawesome/free-regular-svg-icons'; // Import from regular icons
import { Link } from 'react-router-dom';
import './Styles/footer.css';

export default function Footer() {
  const storeLink = '/store'; // Update this link to your store page

  return (
    <footer className='totf'>
      <div className='div'>
        <div className='logo-container'>
          <Link to='/'>
            <img
              className='logo'
              src='./images/Rectangle 393.png'
              alt='Logo'
            />
          </Link>
        </div>
        <div className='feedback-container'>
          <Link className='feedback-link' to='/register'>
            SignUp
          </Link>
          <Link className='feedback-link' to='/'>
            Home
          </Link>
          <Link className='feedback-link' to='/login'>
            SignIn
          </Link>
          <Link
            to={storeLink}
            className='feedback-link'
          >
            Store
          </Link>
        </div>
        <div className='social-buttons'>
          <a
            href='https://www.facebook.com/profile.php?id=61556658964112'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Facebook'
          >
            <FontAwesomeIcon icon={faFacebookF} size='1x' />
          </a>
          <a
            href='https://www.instagram.com/'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Instagram'
          >
            <FontAwesomeIcon icon={faInstagram} size='1x' />
          </a>
          <a
            href='mailto:safelyshareintegral@gmail.com' // Update with your email address
            target='_blank'
            rel='noopener noreferrer'
            aria-label='Mail'
          >
            <FontAwesomeIcon icon={faEnvelope} size='1x' />
          </a>
          <a
            href='https://www.linkedin.com/company/safelyshare/'
            target='_blank'
            rel='noopener noreferrer'
            aria-label='LinkedIn'
          >
            <FontAwesomeIcon icon={faLinkedinIn} size='1x' />
          </a>
        </div>
      </div>
      <div className='conti'>
        <img className='element' src='./images/signinfooter.png' alt='Footer Sign' />
      </div>
    </footer>
  );
}
