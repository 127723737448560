import './Styles/notfound.css';

const NotFound = () => {
  const goBack = () => {
    window.history.back();
  };

  return (
    <div>
      <div className='box'>
        <div className='oops'>
          <h3>Oops</h3>
        </div>
        <div className='error'>
          <h6>Error 404 - Page not found</h6>
        </div>
        <div className='pagefound'>
          <h6>The Page you wanted couldn’t be found......</h6>
        </div>
        <div className='work'>
          <h6>We are working on it</h6>
        </div>
        <div className='returnpage' onClick={goBack}>
          <h6>Return to Home Page</h6>
        </div>
        <div className='future'>
          <h6>
            For further assistance, write to us{' '}
            <a href='mailto:support.team@safelyshare.se'>
              support.team@safelyshare.se
            </a>
          </h6>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
