import React, { useState, useEffect } from 'react';
import { ref, uploadBytesResumable, getDownloadURL } from 'firebase/storage';
import { collection, addDoc } from 'firebase/firestore';
import { storage, db } from '../services/firebaseconig'; // Assuming firebaseconfig contains your Firebase configuration
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import NavBar from './NavBar';
import Footer from './Footer';
import './Styles/AddProducts.css';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { useNavigate, Navigate } from 'react-router-dom';
import { isAuthenticated } from '../services/Auth';
import MetaData from './MetaDatas';

const AddProducts = () => {
  const [productName, setProductName] = useState('');
  const [modelCode, setModelCode] = useState('');
  const [manufacturer, setManufacturer] = useState('');
  const [numComponents, setNumComponents] = useState(0);
  const [productPrice, setProductPrice] = useState(0);
  const [productImg, setProductImg] = useState(null);
  const [description, setDescription] = useState('');
  const [error, setError] = useState('');
  const [loading, setLoading] = useState(false);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const Navigate = useNavigate();
  const [availability, setAvailability] = useState(true); // default to true
  const [availableTimestamp, setAvailableTimestamp] = useState(0); // default to 0

  const types = ['image/png', 'image/jpeg']; // image types
  const navigate = useNavigate();

  const productImgHandler = (e) => {
    let selectedFile = e.target.files[0];
    if (selectedFile && types.includes(selectedFile.type)) {
      setProductImg(selectedFile);
      setError('');
    } else {
      setProductImg(null);
      setError(
        selectedFile ? 'Please select a valid image type (jpg or png)' : ''
      );
    }
  };

  const showSuccessPopup = () => {
    toast.success('Product added successfully!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const addProduct = async (e) => {
    e.preventDefault();

    if (
      !productName ||
      !modelCode ||
      !manufacturer ||
      numComponents === 0 ||
      productPrice === 0 ||
      !productImg ||
      !description
    ) {
      setError('Please fill in all fields');
      return;
    }

    try {
      setLoading(true);
      setError('');

      const auth = getAuth();
      onAuthStateChanged(auth, async (user) => {
        if (user) {
          const storageRef = ref(
            storage,
            `product-images/${new Date().getTime()}_${productImg.name}`
          );

          const uploadTask = uploadBytesResumable(storageRef, productImg);
          const snapshot = await uploadTask;
          const downloadURL = await getDownloadURL(snapshot.ref);
          const lendingPeriodInMilliseconds = 7 * 24 * 60 * 60 * 1000; // 7 days
          const timestamp = new Date().getTime();
          const newAvailableTimestamp = timestamp + lendingPeriodInMilliseconds;

          setAvailability(true); // Set initial availability to true
          setAvailableTimestamp(newAvailableTimestamp);

          await addDoc(collection(db, 'products'), {
            ProductName: productName,
            ModelCode: modelCode,
            Manufacturer: manufacturer,
            NumComponents: numComponents,
            ProductPrice: Number(productPrice),
            ProductImg: downloadURL,
            Description: description,
            UserId: user.uid,
            availability: true,
            availableTimestamp: newAvailableTimestamp,
          });

          showSuccessPopup();

          setProductName('');
          setModelCode('');
          setManufacturer('');
          setNumComponents(0);
          setProductPrice(0);
          setProductImg(null);
          setDescription('');
          document.getElementById('file').value = '';
          setFormSubmitted(true);
        }
      });
    } catch (error) {
      console.error('Error adding product:', error.message);
      setError('Error adding product. Please try again.');
    } finally {
      setLoading(false);
    }
  };
  if (!isAuthenticated()) {
    return <Navigate to='/login' />;
  }

  return (
    <div className='addproduct'>
      <NavBar />
      <MetaData title={'Create Product'} />

      <br></br>
      <br></br>
      <img
        className='signtop1'
        alt='Elementfooter'
        src='./images/signtop.png'
      />
      <img
        className='signtop2'
        alt='Elementfooter'
        src='./images/signtop.png'
      />
      <div className='container-addproduct'>
        <br /><br></br>
        <h2>Product Setup</h2>
        <h5 className='rectagles'></h5>

        <hr />
        <form
          autoComplete='off'
          className='form-group-contianer'
          onSubmit={addProduct}
        >
          <label htmlFor='product-name'>Product Name</label>
          <input
            type='text'
            className='form-control'
            required
            onChange={(e) => setProductName(e.target.value)}
            value={productName}
          />
          <br />
          <label htmlFor='model-code'>Model Code</label>
          <input
            type='text'
            className='form-control'
            required
            onChange={(e) => setModelCode(e.target.value)}
            value={modelCode}
          />
          <br />
          <label htmlFor='manufacturer'>Manufacturer</label>
          <input
            type='text'
            className='form-control'
            required
            onChange={(e) => setManufacturer(e.target.value)}
            value={manufacturer}
          />
          <br />
          <label htmlFor='num-components'>Number of Components</label>
          <input
            type='text'
            className='form-control'
            required
            onChange={(e) => setNumComponents(e.target.value)}
            value={numComponents}
          />
          <br />
          <label htmlFor='product-price'>Product Price</label>
          <input
            type='text'
            className='form-control'
            required
            onChange={(e) => setProductPrice(e.target.value)}
            value={productPrice}
          />
          <br />
          <label htmlFor='description'>Description</label>
          <textarea
            className='form-control'
            required
            onChange={(e) => setDescription(e.target.value)}
            value={description}
          ></textarea>
          <br />
          <label htmlFor='product-img'>Product Image</label>
          <input
            type='file'
            className='form-control'
            id='file'
            required
            onChange={productImgHandler}
          />
          <br />
          <button type='submit' className='btn-addproduct' disabled={loading}>
            {loading ? <span className='spinner'></span> : 'Add Product'}
          </button>
        </form>
        {error && <span className='error-msg'>{error}</span>}

        <br></br>
      </div>
      <br></br>

      <br />
      <br />
      <br />

      <ToastContainer />
      <Footer />
    </div>
  );
};

export default AddProducts;
