import React from 'react';
import './Styles/NavBar.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { isAuthenticated, logout } from '../services/Auth';

export default function NavBar(props) {
  const location = useLocation();
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    navigate('/login');
  };

  return (
    <nav className='navbar navbar-expand-md' style={{ backgroundColor: '#005E89' }}>
      <Link className='nav-home' to='/'>
        <img style={{ width: '70px', height: '50px', right: '-10px' }} src='./images/Rectangle 393.png' alt='Logo' />
      </Link>
      <div className='collapse navbar-collapse' id='navbarsExampleDefault'>
        <div className='navbar-nav ml-auto'>
          {!isAuthenticated() && (
            <>
              <Link className='button-link' to='/register'>signup</Link>
              <Link className='button-link' to='/login'>signin</Link>
              <Link className='button-link' to='/product'>Store</Link>
            </>
          )}
          {isAuthenticated() && (
            <div className='cent'>
              <div className='nav-item'>
                <Link className='nav-link' to='/'>
                  <img className='store_ico' src='/images/home_icon.png' alt='Home' />
                  <div className='tooltip'>Home</div>
                </Link>
              </div>
              <div className='nav-item'>
                <Link className='nav-link' to='/Profile'>
                  <img className='store_ico' src='/images/user_profile_icon.png' alt='Profile' />
                  <div className='tooltip'>Profile</div>
                </Link>
              </div>
              <div className='nav-item'>
                <Link className='nav-link' to='/product'>
                  <img className='store_ico' src='/images/store_iconnew.png' alt='Product' />
                  <div className='tooltip'>Product</div>
                </Link>
              </div>
              <div className='nav-item'>
                <Link className='nav-link' to='/dashboard'>
                  <img className='store_ico' src='/images/dashboard_icon.png' alt='Dashboard' />
                  <div className='tooltip'>Dashboard</div>
                </Link>
              </div>
              <div className='nav-item'>
                <Link className='nav-link' to='/cart'>
                  <img className='store_ico' src='/images/store icon.png' alt='Cart' />
                  <div className='tooltip'>Cart</div>
                </Link>
              </div>
            </div>
          )}
          {isAuthenticated() && (
            <>
              <div>
                <a className='nav-link' onClick={handleLogout} style={{ cursor: 'pointer' }}>
                  <img className='logoutbut' src='/images/logout_btn_top.png' alt='Logout' />
                </a>
              </div>
              <img className='logoutbut1' src='/images/Rectangle 409.png' alt='Separator' />
            </>
          )}
        </div>
      </div>
    </nav>
  );
}
