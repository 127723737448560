// DashboardPage.jsx

import './Styles/dashboard.css';
import NavBar from './NavBar';
import MetaData from './MetaDatas';
import { useEffect, useState } from 'react';
import { useNavigate, Navigate } from 'react-router-dom';
import { UserDetailsApi } from '../services/api';
import { logout, isAuthenticated } from '../services/Auth';

export default function DashboardPage() {
    const navigate = useNavigate();

    const [user, setUser] = useState({ name: '', email: '' });

    useEffect(() => {
        if (isAuthenticated()) {
            UserDetailsApi().then((response) => {
                setUser({
                    name: response.data.users[0].displayName,
                    email: response.data.users[0].email,
                });
            });
        }
    }, []);

    const logoutUser = () => {
        logout();
        navigate('/login');
    };

    if (!isAuthenticated()) {
        return <Navigate to='/login' />;
    }

    return (
        <div>
            <NavBar logoutUser={logoutUser} />
            <MetaData title={'Dashboard'} />

            <div className='dash'>
                My Dashboard
            </div>
            <div className='underline3'>
                <div className='underline2'>
                    <div className='underline1'></div>
                </div>
            </div>
            <br />
            <div className='dashboard-container'>
                <div className='column'>
                    <div className='label'>
                        <div className='textalign'>
                            PRODUCTS LENT
                           
                        </div>
                    </div>
                    <div className='products-borrowed'>
                        <div className='textalign'>
                            Products Borrowed
                            <br></br>
                            <br></br>
                            <button id='show'>Show Products</button>
                        </div>


                    </div>
                </div>

                <div className='column'>
                    <div className='earnings'>
                        <div className='textalign'>
                            Earnings in Current Month
                        </div>
                        <p id='dollar'>#$</p>

                    </div>
                    <br></br>
                    <br></br>
                    <div className='track'>
                        <div className='textalign'>
                            Track down your products lending

                            <br></br>
                            <br></br>
                            <button id='show'>Click Here</button>
                        </div>

                    </div>
                    <br></br>
                    <br></br>
                    <div className='borrow-now'>
                        <div className='textalign'>
                            Want to borrow a Product ?
                            <br></br>
                            <br></br>
                            <button id='show1'> Click Here </button>
                        </div>


                    </div>
                </div>

                <div className='column'>
                    <div className='cust-idoutline'>
                        <div className='cust-id'>
                        
            {user.name && user.email ? (
              <div>
                <p className='text1 '>Hi {user.name}</p>
                <p id='user'>Your email is {user.email}</p>
              </div>
            ) : (
              <p>Loading...</p>
            )}
                </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                    
                    <div className='stat'>
                        <div className='text'>
                            See the statistical overview
                            of your products
                            <div className='icon'>
                                <button class="custom-button" onclick="handleButtonClick()">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="125" height="95" viewBox="0 0 125 95" fill="none">

                                        <g filter="url(#filter0_d_572_276)">
                                            <rect x="20" y="10" width="85" height="55" rx="10" fill="#693CE9" />
                                        </g>
                                        <svg xmlns="http://www.w3.org/2000/svg" width="125" height="95" viewBox="0 0 125 95" fill="none">
                                            <g filter="url(#filter0_d_572_276)">
                                                <rect x="20" y="10" width="85" height="55" rx="10" fill="#693CE9" />
                                            </g>
                                            <line y1="-0.875" x2="15.9476" y2="-0.875" transform="matrix(0.588622 -0.808408 0.863238 0.504798 42 54.4465)" stroke="#FFF8F8" stroke-width="1.75" />
                                            <path d="M69.7885 37.6184L50.4375 41.076" stroke="#FFF8F8" stroke-width="1.75" />
                                            <path d="M82.8963 19.2544C82.7988 18.8062 82.3184 18.5303 81.8234 18.638L73.7555 20.3934C73.2604 20.5012 72.9381 20.9518 73.0356 21.3999C73.1331 21.8481 73.6135 22.124 74.1086 22.0163L81.28 20.4559L82.6925 26.9475C82.79 27.3956 83.2704 27.6716 83.7655 27.5639C84.2606 27.4562 84.5829 27.0055 84.4854 26.5574L82.8963 19.2544ZM69.3447 38.6402L82.7586 19.8853L81.2412 19.0136L67.8272 37.7685L69.3447 38.6402Z" fill="#FFFBFB" />
                                            <rect x="51.3984" y="40.3727" width="5.28011" height="14.1795" transform="rotate(0.650256 51.3984 40.3727)" fill="#FFFBFB" />
                                            <rect x="64.625" y="38.1595" width="5.28011" height="16.5427" transform="rotate(0.650256 64.625 38.1595)" fill="#FFFBFB" />
                                            <rect x="75.293" y="28.8269" width="5.28011" height="25.9957" transform="rotate(0.650256 75.293 28.8269)" fill="#FFFBFB" />
                                            <ellipse cx="51.3983" cy="40.3727" rx="1.32003" ry="1.18162" transform="rotate(0.650256 51.3983 40.3727)" fill="#FFFEFE" />
                                            <ellipse cx="68.5995" cy="37.0229" rx="1.32003" ry="1.18162" transform="rotate(0.650256 68.5995 37.0229)" fill="#FFFEFE" />
                                            <ellipse cx="75.2929" cy="28.8269" rx="1.32003" ry="1.18162" transform="rotate(0.650256 75.2929 28.8269)" fill="#FFFEFE" />
                                            <defs>
                                                <filter id="filter0_d_572_276" x="0" y="0" width="125" height="95" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
                                                    <feFlood flood-opacity="0" result="BackgroundImageFix" />
                                                    <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha" />
                                                    <feOffset dy="10" />
                                                    <feGaussianBlur stdDeviation="10" />
                                                    <feComposite in2="hardAlpha" operator="out" />
                                                    <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
                                                    <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_572_276" />
                                                    <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_572_276" result="shape" />
                                                </filter>
                                            </defs>
                                        </svg>
                                    </svg>
                                </button>
                            </div>
                        </div>
                    </div>
                    <br></br>
                    <br></br>
                    <br></br>
                   
                    <div className='stat'>
                        <div className='text'>
                            All Time Earnings
                            <p id='dollar'>#$</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
