import React, { useState, useEffect } from 'react';

import NavBar from './NavBar';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useCart } from '../services/CartContext'; // Import the useCart hook
import './Styles/Payment.css';

export default function CardDetailsForm() {
  const [cardNumber, setCardNumber] = useState('');
  const [cardType, setCardType] = useState('');
  const [expiration, setExpiration] = useState('');
  const [cvv, setCvv] = useState('');

  const { dispatch } = useCart();

  useEffect(() => {
    // Display a toast message when the component mounts
    toast.info(
      'Welcome to the testing page! there is no need to fill any orginal detials here',
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }, []);

  const handleCardNumberChange = (event) => {
    const inputCardNumber = event.target.value.replace(/\D/g, '');
    setCardNumber(inputCardNumber);
    identifyCardType(inputCardNumber);
  };

  const identifyCardType = (cardNumber) => {
    const visaRegex = /^4/;
    const mastercardRegex = /^5[1-5]/;
    const amexRegex = /^3[47]/;
    const discoverRegex = /^6(?:011|5[0-9]{2})/;

    if (visaRegex.test(cardNumber)) {
      setCardType('visa');
    } else if (mastercardRegex.test(cardNumber)) {
      setCardType('mastercard');
    } else if (amexRegex.test(cardNumber)) {
      setCardType('amex');
    } else if (discoverRegex.test(cardNumber)) {
      setCardType('discover');
    } else {
      setCardType('');
    }
  };

  const handleExpirationChange = (event) => {
    setExpiration(event.target.value);
  };

  const handleCvvChange = (event) => {
    const inputCvv = event.target.value.replace(/\D/g, '').slice(0, 3);
    setCvv(inputCvv);
  };

  const handleSubmit = (event) => {
    event.preventDefault();

    if (!cardNumber || !expiration || !cvv) {
      toast.error('Please fill in all fields.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (cardNumber.length !== 16) {
      toast.error('Please enter a valid 16-digit card number.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    const today = new Date();
    const inputExpiration = new Date(expiration);
    if (inputExpiration <= today) {
      toast.error('Please enter a valid expiration date.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    if (cvv.length !== 3) {
      toast.error('Please enter a valid 3-digit CVV.', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return;
    }

    // Dispatch action to clear cart after placing order
    dispatch({ type: 'PLACE_ORDER' });

    toast.success('Your order is placed!', {
      position: 'top-right',
      autoClose: 3000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  return (
    <div>
      <NavBar />
      <br />
      <br />
      <br />
      <div className='cont'>
        <div className='container-s'>
          <div className='form'>
            <h1>Card Details</h1>
            <div className='fields fields--2'>
              <label className='field' htmlFor='cardnumber'>
                <span className='field__label'>Card Number</span>
                <input
                  className='field__input'
                  type='text'
                  id='cardno'
                  maxLength={16}
                  placeholder='Card Number'
                  value={cardNumber}
                  onChange={handleCardNumberChange}
                />
              </label>

              <div className='card-type'>
                {cardType && (
                  <img
                    src={`https://img.icons8.com/color/48/000000/${cardType}.png`}
                    alt={cardType}
                    width='48px'
                  />
                )}
              </div>
            </div>
            <label className='field' htmlFor='cardholder name'>
              <span className='field__label'>Card HolderName</span>
              <input
                className='field__input'
                type='text'
                id='name'
                placeholder='Card HolderName'
              />
            </label>
            <label className='field' htmlFor='expiration'>
              <span className='field__label'>Expiration (MM/YYYY)</span>
              <input
                className='field__input'
                type='month'
                id='expiry'
                min={new Date().toISOString().split('T')[0].substring(0, 7)}
                placeholder='Expiration'
                value={expiration}
                onChange={handleExpirationChange}
              />
            </label>
            <label className='field' htmlFor='cvv'>
              <span className='field__label'>CVV</span>
              <input
                className='field__input'
                type='text'
                id='cvv'
                placeholder='CVV'
                maxLength={3}
                value={cvv}
                onChange={handleCvvChange}
              />
            </label>
          </div>
          <br />
          <button type='submit' className='button' onClick={handleSubmit}>
            Pay Now
          </button>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}
