import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from './Footer';
import MetaData from './MetaDatas';
import { useNavigate } from 'react-router-dom';
import {
  collection,
  getDocs,
  doc,
  getDoc,
  deleteDoc,
  where,
  query,
} from 'firebase/firestore';
import { db } from '../services/firebaseconig';
import './Styles/cart.css';
import { getAuth } from 'firebase/auth';

const Cart = () => {
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchCartItems = async () => {
      try {
        const auth = getAuth();
        const user = auth.currentUser;

        if (user) {
          const cartDocRef = collection(db, 'carts');
          const cartsSnapshot = await getDocs(
            query(cartDocRef, where('userId', '==', user.uid))
          );

          const cartItemsData = [];

          for (const cartDoc of cartsSnapshot.docs) {
            const cartData = cartDoc.data();

            const productDocRef = doc(db, 'products', cartData.productId);

            try {
              const productSnapshot = await getDoc(productDocRef);

              if (productSnapshot.exists()) {
                const productData = {
                  id: productSnapshot.id,
                  ...productSnapshot.data(),
                };

                const cartItem = {
                  id: cartDoc.id,
                  cartDocId: cartDoc.id, // Add cart document ID
                  ...cartData,
                  ...productData,
                };

                cartItemsData.push(cartItem);
              } else {
                console.error(
                  `Product not found for productId: ${cartData.productId}`
                );
              }
            } catch (error) {
              console.error('Error fetching product:', error.message);
            }
          }

          setCartItems(cartItemsData);
        } else {
          console.log('User not logged in');
          setCartItems([]); // Set an empty array when the user is not logged in
        }
      } catch (error) {
        console.error('Error fetching cart items:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchCartItems();
  }, []);

  const handleRemoveItem = async (itemId, cartDocId) => {
    try {
      console.log('Removing item with ID:', itemId);

      // Reference to the cart document in Firestore
      const cartDocRef = doc(db, 'carts', cartDocId);

      // Delete the cart document
      await deleteDoc(cartDocRef);

      console.log(`Cart document with ID ${cartDocId} removed successfully from Firestore.`);

      // Remove the item from the state
      setCartItems((prevCartItems) =>
        prevCartItems.filter((item) => item.id !== itemId)
      );

      console.log(`Item with ID ${itemId} removed successfully from the cart.`);
    } catch (error) {
      console.error('Error removing item from cart:', error.message);
    }
  };

  const handleProceedToCheckout = async () => {
    try {
      // Your existing logic for navigating to the checkout page
      navigate('/Shipping-Detials');

      // Assuming a successful checkout, get the user's ID
      const auth = getAuth();
      const user = auth.currentUser;

      if (user) {
        // Reference to the cart document in Firestore
        const cartDocRef = collection(db, 'carts');

        // Query for the user's cart based on userId
        const userCartQuery = query(
          cartDocRef,
          where('userId', '==', user.uid)
        );
        const userCartSnapshot = await getDocs(userCartQuery);

        // Delete the cart document
        userCartSnapshot.forEach(async (doc) => {
          await deleteDoc(doc.ref);
          console.log(
            `Cart document deleted successfully for user: ${user.uid}`
          );
        });
      }
    } catch (error) {
      console.error('Error during checkout:', error.message);
    }
  };

  const calculateTotalPrice = (items) => {
    return items
      .reduce((total, item) => total + (item.ProductPrice || 0), 0)
      .toFixed(2);
  };

  if (loading) {
    return (
      <div className='loader-container-bat'>
        <div className=''></div>
      </div>
    );
  }

  return (
    <div className='cart'>
      <NavBar />
      <MetaData title='Cart' />
      <div className='cart-wrapper'>
        <h2>Your Cart</h2>
        {cartItems.length === 0 ? (
          <p>Your cart is empty.</p>
        ) : (
          <div className='cart-items'>
            {cartItems.map((item) => (
              <div key={item.id} className='cart-item'>
                <img src={item.ProductImg} alt={item.ProductName} />
                <div className='item-details'>
                  <h3>{item.ProductName}</h3>
                  <p>{item.Description}</p>
                  <p>${item.ProductPrice.toFixed(2)}</p>
                  <button
                    className='remove-btn'
                    onClick={() => handleRemoveItem(item.id, item.cartDocId)} // Pass cart document ID
                  >
                    Remove
                  </button>
                </div>
              </div>
            ))}
            <div className='cart-summary'>
              <h3>Order Summary</h3>
              <p>Total Items: {cartItems.length}</p>
              <p>Total Price: ${calculateTotalPrice(cartItems)}</p>
              <button
                className='checkout-btn'
                onClick={handleProceedToCheckout}
              >
                Proceed to Checkout
              </button>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </div>
  );
};

export default Cart;
