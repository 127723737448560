import React from 'react';
import './Styles/Welcome.css';
import { Link } from 'react-router-dom';

export const Welcome = () => {
  return (
    <div className='wel-pg'>
      <div className='overlap-wrapper'>
        <div className='overlap'>
          <img className='element' alt='Element' src='./images/wel.png' />
          <p className='welcome'>
            <span className='span'>Welcome</span>
            <span className='text-wrapper-4'>
              {' '}
              <br />
            </span>
          </p>

          <Link className='prod-w' to='/register'>
            <img
              src='./images/Group 218.png'
              alt='Logo'
              style={{ width: '89.5px', height: '42px' }}
            />
          </Link>
          <div className='text-wrapper-5'>Safely Share</div>
          <div className='text-wrapper-6'>TO</div>
          <p className='p'>
            Get started by setting up your product in few clicks. After product
            set up,You can start lending your product and earn Else explore our
            store to lend lego today
          </p>
          <Link className='store-w' to='/product'>
            <img
              src='./images/Group 149.png'
              alt='Logo'
              style={{ width: '89.5px', height: '42px' }}
            />
          </Link>
        </div>
      </div>
    </div>
  );
};
