import React, { useEffect, useState } from 'react';
import NavBar from '../components/NavBar';
import Footer from './Footer';
import MetaData from './MetaDatas';
import { useParams } from 'react-router-dom';
import { db } from '../services/firebaseconig';
import './Styles/productdetails.css';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { logout, isAuthenticated } from '../services/Auth';
import { useNavigate } from 'react-router-dom';
import { useCart } from '../services/CartContext';
import {
  collection,
  getDocs,
  addDoc,
  serverTimestamp,
  where,
  query,
} from 'firebase/firestore';
import { getAuth } from 'firebase/auth';
import { Link } from 'react-router-dom';

export default function ProductDetails() {
  const { id } = useParams();
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const { dispatch } = useCart();
  const [addToCartLoading, setAddToCartLoading] = useState(false);

  useEffect(() => {
    const fetchProductData = async () => {
      try {
        const productsCollection = collection(db, 'products');
        const productsSnapshot = await getDocs(productsCollection);

        const productsData = productsSnapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));

        const product = productsData.find((product) => product.id === id);

        if (product) {
          setSelectedProduct(product);
        } else {
          console.error('Product not found');
        }
      } catch (error) {
        console.error('Error fetching products:', error.message);
      } finally {
        setLoading(false);
      }
    };

    fetchProductData();
  }, [id]);

  const handleAddToCart = async () => {
    if (!isAuthenticated()) {
      toast.warning('Please log in to add the item to cart!! 😊', {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    } else {
      try {
        setAddToCartLoading(true); // Set loading state to true
  
        const auth = getAuth();
        const user = auth.currentUser;
  
        if (user) {
          const cartCollectionRef = collection(db, 'carts');
          const cartQuery = query(
            cartCollectionRef,
            where('userId', '==', user.uid),
            where('productId', '==', selectedProduct.id)
          );
  
          const cartSnapshot = await getDocs(cartQuery);
  
          if (!cartSnapshot.empty) {
            toast.info('This item is already added to the cart.', {
              position: 'top-right',
              autoClose: 3000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
            });
          } else {
            const cartItem = {
              productId: selectedProduct.id,
              quantity: 1,
              addedAt: serverTimestamp(),
              userId: user.uid,
            };
  
            await addDoc(cartCollectionRef, cartItem);
            navigate('/cart');
          }
        }
      } catch (error) {
        console.error('Error adding item to cart:', error.message);
      } finally {
        setAddToCartLoading(false);
      }
    }
  };
  
  if (loading) {
    return (
      <div className='loader-container'>
        <div className='loader'></div>
      </div>
    );
  }

  if (!selectedProduct) {
    return <p>Error loading product...</p>;
  }

  return (
    <div>
      <NavBar />
      <MetaData title={`ProductDetails - ${selectedProduct.ProductName}`} />
      <div className='card-wrapper'>
        <div className='card'>
          <div className='product-imgs img-display'>
            <div className='img-showcase'>
              <Link to={`/product-details/${selectedProduct.id}`}>
                <img
                  src={selectedProduct.ProductImg}
                  alt={selectedProduct.ProductName}
                />
              </Link>
            </div>
          </div>
          <h2 className='product-title'>{selectedProduct.ProductName}</h2>
          <div className='product-content'>
            <p className='product-description'>
              Product Description: {selectedProduct.Description}
            </p>
            <p className='product-price'>
              Price: Kr.{selectedProduct.ProductPrice}
            </p>
            <div className='purchase-info'>
              <button type='button' className='btn' onClick={handleAddToCart}>
                Add to Cart
              </button>
            </div>
          </div>
        </div>
        <ToastContainer />
      </div>
      <Footer />
    </div>
  );
}
