
import React, { useState, useEffect } from 'react';
import './Styles/product.css';
import { Link, useLocation, useNavigate } from 'react-router-dom';


    const Snav = ({ setMinValue, setMaxValue }) => {
        const [minInput, setMinInput] = useState('');
        const [maxInput, setMaxInput] = useState('');
    
        const handleMinInputChange = (e) => {
            const minValue = parseInt(e.target.value, 10);
            setMinInput(minValue);
            if (!isNaN(minValue) && !isNaN(maxInput)) {
                setMinValue(minValue);
            }
        };
    
        const handleMaxInputChange = (e) => {
            const maxValue = parseInt(e.target.value, 10);
            setMaxInput(maxValue);
            if (!isNaN(minInput) && !isNaN(maxValue)) {
                setMaxValue(maxValue);
            }
        };

    const handleFilterButtonClick = () => {
        const minValue = parseInt(minInput, 10);
        const maxValue = parseInt(maxInput, 10);

        if (!isNaN(minValue) && !isNaN(maxValue)) {
            setMinValue(minValue);
            setMaxValue(maxValue);
            // Trigger the filter action
        }
    };
    return (
        <div className='sidenav'>

            <img
                className='ele'
                src='./images/1000000194.png'
            />

            <div className='im12'>
                <div style={{ position: 'relative', display: 'inline-block' }}>
                    <img
                        src='./images/Group 225 (1).png'
                        alt='Filter'
                        style={{ width: '100%', height: 'auto' }} // Adjust image styling as needed
                    />
                    <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                        <text id='im1q'>Filter</text>
                    </div>
                </div>
            </div>
            <div className='small-boxes-containers'>
                <div className='smallbox3q'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
                        <path d="M2.16797 1.26006C2.23921 3.0467 3.19931 4.47161 3.7784 5.41016C4.24556 6.1673 4.89308 7.21341 5.43593 7.52458C5.85561 7.76516 6.23347 8.16881 6.63198 8.5593C7.23591 9.15107 7.85293 9.35298 8.47786 9.76272C9.52665 10.4504 10.5073 11.2007 11.5763 11.6972C12.5946 12.1701 13.5574 12.8274 14.5994 13.0918C15.5583 13.3352 16.4629 13.7373 17.4059 14.059C17.7837 14.188 18.1282 14.6472 18.5078 14.7788C18.8259 14.8892 19.1572 14.8111 19.4778 14.8238C19.9933 14.8443 20.6973 14.8038 21.2012 15.0713C22.6669 15.8492 24.605 16.2176 26.0655 15.1275C26.978 14.4464 28.0821 14.8238 29.0368 14.8238" stroke="#6FB8C0" stroke-linecap="round" />
                        <line x1="2.12109" y1="17.9559" x2="29.9388" y2="17.9559" stroke="#6FB8C0" />
                        <line x1="1.07422" x2="1.07422" y2="18.4559" stroke="#6FB8C0" />
                        <path d="M3.67021 10.5509C3.42866 10.0789 2.88036 8.80187 3.16206 7.93679C3.4578 7.02864 4.18744 9.5343 4.30051 10.0024C4.47259 10.7148 4.12163 10.6465 3.89497 10.475C3.42003 10.1158 3.01163 9.13852 2.8347 8.0535C2.73072 7.41588 2.59096 6.65445 2.38518 6.16294C2.35524 6.09142 1.90953 5.07373 2.08957 5.36937C2.27433 5.67275 2.37259 6.21256 2.34609 6.73477C2.27222 8.19029 2.26303 9.6052 2.26303 11.0761C2.26303 11.4115 2.27777 14.6517 2.55375 13.9352C2.78068 13.3461 2.90778 12.5972 3.079 11.928C3.25272 11.249 3.72612 13.2716 3.78259 13.5735C3.88751 14.1343 3.71687 13.6052 3.59204 13.4334C3.57176 13.4055 3.39198 13.264 3.45034 13.2934C4.17028 13.6554 4.94595 13.4354 5.67105 13.3284C6.43145 13.2161 7.20678 12.998 7.96995 13.095C8.34796 13.143 9.36118 14.0108 8.28754 12.8849C7.93093 12.5109 6.8201 11.3641 6.46993 11.006C6.27971 10.8115 6.08936 10.6178 5.89826 10.4284C5.8257 10.3564 5.74888 10.172 5.67594 10.2416C5.6634 10.2536 6.00738 10.7342 6.03018 10.761C6.35657 11.1439 6.7239 11.3899 7.03182 11.858C7.12414 11.9983 6.81216 11.8458 6.70446 11.7996C5.70509 11.3707 4.7129 10.848 3.71419 10.4109C3.52317 10.3272 3.3257 10.2358 3.1303 10.2358C2.82442 10.2358 3.728 10.5473 4.0269 10.7026C4.94616 11.1802 5.86287 11.6673 6.77775 12.1905C7.03708 12.3389 7.32981 12.3617 7.57906 12.5932C7.86401 12.8578 7.418 12.6205 7.35186 12.5815C6.99146 12.3688 6.62251 12.1624 6.25005 12.1264C5.94303 12.0966 6.38616 12.2352 6.44061 12.2664C7.68098 12.9779 8.86689 14.122 10.1565 14.122C10.2485 14.122 9.97226 14.1135 9.8804 14.0986C9.41586 14.0234 8.95403 13.8314 8.50253 13.5618C8.42758 13.517 8.2054 13.3867 8.28265 13.3867C8.75902 13.3867 9.23285 13.7449 9.69961 13.9236C10.6504 14.2875 11.6156 14.227 12.5751 14.227C12.8601 14.227 13.1388 14.5002 13.4252 14.5362C13.6546 14.5651 13.8869 14.5421 14.1166 14.5421C14.5396 14.5421 14.9028 14.8572 15.3235 14.8572" stroke="#6FB8C0" stroke-linecap="round" />
                    </svg>
                </div>
                <div className='smallbox3q'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="20" viewBox="0 0 30 19" fill="none">
                        <path d="M2.16797 1.26006C2.23921 3.0467 3.19931 4.47161 3.7784 5.41016C4.24556 6.1673 4.89308 7.21341 5.43593 7.52458C5.85561 7.76516 6.23347 8.16881 6.63198 8.5593C7.23591 9.15107 7.85293 9.35298 8.47786 9.76272C9.52665 10.4504 10.5073 11.2007 11.5763 11.6972C12.5946 12.1701 13.5574 12.8274 14.5994 13.0918C15.5583 13.3352 16.4629 13.7373 17.4059 14.059C17.7837 14.188 18.1282 14.6472 18.5078 14.7788C18.8259 14.8892 19.1572 14.8111 19.4778 14.8238C19.9933 14.8443 20.6973 14.8038 21.2012 15.0713C22.6669 15.8492 24.605 16.2176 26.0655 15.1275C26.978 14.4464 28.0821 14.8238 29.0368 14.8238" stroke="#6FB8C0" stroke-linecap="round" />
                        <line x1="2.12109" y1="17.9559" x2="29.9388" y2="17.9559" stroke="#6FB8C0" />
                        <line x1="1.07422" x2="1.07422" y2="18.4559" stroke="#6FB8C0" />
                        <path d="M3.67021 10.5509C3.42866 10.0789 2.88036 8.80187 3.16206 7.93679C3.4578 7.02864 4.18744 9.5343 4.30051 10.0024C4.47259 10.7148 4.12163 10.6465 3.89497 10.475C3.42003 10.1158 3.01163 9.13852 2.8347 8.0535C2.73072 7.41588 2.59096 6.65445 2.38518 6.16294C2.35524 6.09142 1.90953 5.07373 2.08957 5.36937C2.27433 5.67275 2.37259 6.21256 2.34609 6.73477C2.27222 8.19029 2.26303 9.6052 2.26303 11.0761C2.26303 11.4115 2.27777 14.6517 2.55375 13.9352C2.78068 13.3461 2.90778 12.5972 3.079 11.928C3.25272 11.249 3.72612 13.2716 3.78259 13.5735C3.88751 14.1343 3.71687 13.6052 3.59204 13.4334C3.57176 13.4055 3.39198 13.264 3.45034 13.2934C4.17028 13.6554 4.94595 13.4354 5.67105 13.3284C6.43145 13.2161 7.20678 12.998 7.96995 13.095C8.34796 13.143 9.36118 14.0108 8.28754 12.8849C7.93093 12.5109 6.8201 11.3641 6.46993 11.006C6.27971 10.8115 6.08936 10.6178 5.89826 10.4284C5.8257 10.3564 5.74888 10.172 5.67594 10.2416C5.6634 10.2536 6.00738 10.7342 6.03018 10.761C6.35657 11.1439 6.7239 11.3899 7.03182 11.858C7.12414 11.9983 6.81216 11.8458 6.70446 11.7996C5.70509 11.3707 4.7129 10.848 3.71419 10.4109C3.52317 10.3272 3.3257 10.2358 3.1303 10.2358C2.82442 10.2358 3.728 10.5473 4.0269 10.7026C4.94616 11.1802 5.86287 11.6673 6.77775 12.1905C7.03708 12.3389 7.32981 12.3617 7.57906 12.5932C7.86401 12.8578 7.418 12.6205 7.35186 12.5815C6.99146 12.3688 6.62251 12.1624 6.25005 12.1264C5.94303 12.0966 6.38616 12.2352 6.44061 12.2664C7.68098 12.9779 8.86689 14.122 10.1565 14.122C10.2485 14.122 9.97226 14.1135 9.8804 14.0986C9.41586 14.0234 8.95403 13.8314 8.50253 13.5618C8.42758 13.517 8.2054 13.3867 8.28265 13.3867C8.75902 13.3867 9.23285 13.7449 9.69961 13.9236C10.6504 14.2875 11.6156 14.227 12.5751 14.227C12.8601 14.227 13.1388 14.5002 13.4252 14.5362C13.6546 14.5651 13.8869 14.5421 14.1166 14.5421C14.5396 14.5421 14.9028 14.8572 15.3235 14.8572" stroke="#6FB8C0" stroke-linecap="round" />
                    </svg>
                </div>

                <div className='smallbox3q'>
                    <svg xmlns="http://www.w3.org/2000/svg" width="30" height="19" viewBox="0 0 30 19" fill="none">
                        <path d="M2.16797 1.26006C2.23921 3.0467 3.19931 4.47161 3.7784 5.41016C4.24556 6.1673 4.89308 7.21341 5.43593 7.52458C5.85561 7.76516 6.23347 8.16881 6.63198 8.5593C7.23591 9.15107 7.85293 9.35298 8.47786 9.76272C9.52665 10.4504 10.5073 11.2007 11.5763 11.6972C12.5946 12.1701 13.5574 12.8274 14.5994 13.0918C15.5583 13.3352 16.4629 13.7373 17.4059 14.059C17.7837 14.188 18.1282 14.6472 18.5078 14.7788C18.8259 14.8892 19.1572 14.8111 19.4778 14.8238C19.9933 14.8443 20.6973 14.8038 21.2012 15.0713C22.6669 15.8492 24.605 16.2176 26.0655 15.1275C26.978 14.4464 28.0821 14.8238 29.0368 14.8238" stroke="#6FB8C0" stroke-linecap="round" />
                        <line x1="2.12109" y1="17.9559" x2="29.9388" y2="17.9559" stroke="#6FB8C0" />
                        <line x1="1.07422" x2="1.07422" y2="18.4559" stroke="#6FB8C0" />
                        <path d="M3.67021 10.5509C3.42866 10.0789 2.88036 8.80187 3.16206 7.93679C3.4578 7.02864 4.18744 9.5343 4.30051 10.0024C4.47259 10.7148 4.12163 10.6465 3.89497 10.475C3.42003 10.1158 3.01163 9.13852 2.8347 8.0535C2.73072 7.41588 2.59096 6.65445 2.38518 6.16294C2.35524 6.09142 1.90953 5.07373 2.08957 5.36937C2.27433 5.67275 2.37259 6.21256 2.34609 6.73477C2.27222 8.19029 2.26303 9.6052 2.26303 11.0761C2.26303 11.4115 2.27777 14.6517 2.55375 13.9352C2.78068 13.3461 2.90778 12.5972 3.079 11.928C3.25272 11.249 3.72612 13.2716 3.78259 13.5735C3.88751 14.1343 3.71687 13.6052 3.59204 13.4334C3.57176 13.4055 3.39198 13.264 3.45034 13.2934C4.17028 13.6554 4.94595 13.4354 5.67105 13.3284C6.43145 13.2161 7.20678 12.998 7.96995 13.095C8.34796 13.143 9.36118 14.0108 8.28754 12.8849C7.93093 12.5109 6.8201 11.3641 6.46993 11.006C6.27971 10.8115 6.08936 10.6178 5.89826 10.4284C5.8257 10.3564 5.74888 10.172 5.67594 10.2416C5.6634 10.2536 6.00738 10.7342 6.03018 10.761C6.35657 11.1439 6.7239 11.3899 7.03182 11.858C7.12414 11.9983 6.81216 11.8458 6.70446 11.7996C5.70509 11.3707 4.7129 10.848 3.71419 10.4109C3.52317 10.3272 3.3257 10.2358 3.1303 10.2358C2.82442 10.2358 3.728 10.5473 4.0269 10.7026C4.94616 11.1802 5.86287 11.6673 6.77775 12.1905C7.03708 12.3389 7.32981 12.3617 7.57906 12.5932C7.86401 12.8578 7.418 12.6205 7.35186 12.5815C6.99146 12.3688 6.62251 12.1624 6.25005 12.1264C5.94303 12.0966 6.38616 12.2352 6.44061 12.2664C7.68098 12.9779 8.86689 14.122 10.1565 14.122C10.2485 14.122 9.97226 14.1135 9.8804 14.0986C9.41586 14.0234 8.95403 13.8314 8.50253 13.5618C8.42758 13.517 8.2054 13.3867 8.28265 13.3867C8.75902 13.3867 9.23285 13.7449 9.69961 13.9236C10.6504 14.2875 11.6156 14.227 12.5751 14.227C12.8601 14.227 13.1388 14.5002 13.4252 14.5362C13.6546 14.5651 13.8869 14.5421 14.1166 14.5421C14.5396 14.5421 14.9028 14.8572 15.3235 14.8572" stroke="#6FB8C0" stroke-linecap="round" />
                    </svg>
                </div>

            </div>

            <div className='imboxblue'>
                <br></br>
                <div className='pricerang'>
                    <text id='prir1'>Price Range</text>
                    <br />
                    <br />

                    <div className='inpucontainer'>
                        <label htmlFor='minInput' >Min</label>

                        <label htmlFor='maxInput'>Max</label>

                    </div>
                    <div className='inpucontainer'>
                        <input
                            type='number'
                            id='minI'
                            value={minInput}
                            onChange={handleMinInputChange}
                        />

                        <input
                            type='number'
                            id='maxI'
                            value={maxInput}
                            onChange={handleMaxInputChange}
                        />


                    </div>
                    <br></br>
                    <div className="sliding-range">
                <input
                    type='range'
                    min={0}
                    max={1000} // Update with your desired max range
                    step='1'
                    value={minInput}
                    onChange={handleMinInputChange}
                    className='slider'
                />
                <input
                    type='range'
                    min={0}
                    max={1000} // Update with your desired max range
                    step='1'
                    value={maxInput}
                    onChange={handleMaxInputChange}
                    className='slider'
                />
            </div>
            <br/>

                    <div className='im'>
                        <div style={{ position: 'relative', display: 'inline-block' }}>

                            <div style={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-60%, -50%)' }}>
                                <button id='imwq' onClick={handleFilterButtonClick} >Apply </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        
      {/*   <div className='typbox' >
        <img src='./images/1000000201 (1).png' />
        <text
            id='prt'
            style={{
                position: 'absolute',
                top: '10%',
                left: '25%',
                transform: 'translate(-50%, -40%)',
                color: '#FFF',
                textAlign: 'center',
                fontFamily: 'Sniglet',
                fontSize: '20px',
            }}
        >
            Product Type
        </text>
       <div className='typlist'>
    // <div className='typname'></div>
    // <div className='typname'></div>
    <div className='typname'></div>
    <div className='typname'></div>
    <div className='typname'></div>
    <div className='typname'></div>
    <div className='typname'></div>
</div> */}

{/* <div className='myp' style={{ position: 'absolute' }}>
    <img src='/images/Group 225 (1).png' alt='Image' />
    <Link className='nav-link' to='/dashboard'>
        <div className='text-overlay' style={{ position: 'absolute', top: '-60%', left: '28%', transform: 'translate(-50%, -50%)', color: '#000', fontSize: '19px', fontWeight: 'bold' }}>
            My Products
        </div>
    </Link>
</div> 

</div>*/}

</div>












    );
};
export default Snav;