
import React from 'react';
import './Styles/ProductList.css';
import NavBar from './NavBar';
import Product from './Product';

const ProductList = () => {
  const handleBuyNowClick = (product) => {
    console.log(`Product "${product.name}" was clicked. Add your logic here.`);
  };

  const products = [
    {
      id: 1,
      name: 'Marvel',
      price: 19.99,
      image: './images/Logo.png',
      description: 'Good Product',
    },
 
  ];

  return (
    <div>
      <NavBar />
      <div className='app'>
        <div className='product-list-container'>
          <div className='right-container'>
            <div className='product-list'>
              {products.map((product) => (
                <Product
                  key={product.id}
                  product={product}
                  onBuyNowClick={handleBuyNowClick}
                />
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductList;
