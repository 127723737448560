import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import NavBar from './NavBar';
import Footer from './Footer';
import MetaData from './MetaDatas';

import './Styles/Landpage.css';

export default function Landingpage() {
  useEffect(() => {
    toast.info(
      'Welcome to SafelyShare! We are currently in testing and would be happy for your feedback',
      {
        position: 'top-right',
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      }
    );
  }, []);

  return (
    <div>
      <NavBar></NavBar>
      <img className='bluebox1' src='/images/blue_design 1.png' alt='' />
      <div className='bluecont'>
        <img
          className='bluecontbgimg'
          src='/images/bg_lego joints.png'
          alt=''
        />
        <div className='frcont'>Explore our Products</div>
        <div className='frcont2'>
          A secure platform to lend and borrow your products
        </div>
      </div>
      <div className='butfrco'>
        <Link className='ihms' to='/product'>
          <img src='/images/frcont.png' alt='product' />
        </Link>
      </div>
      <div>
        <img
          className='hmpgill'
          src='/images/home_page_illustration.png'
          alt=''
        />
      </div>
      <div className='co1'>
        The ultimate platform for LEGO enthusiasts to share and exchange their
        beloved bricks! Whether you're a seasoned collector or a casual builder,
        LegoShare has everything you need to take your LEGO experience to the
        next level.
        <br></br>
        <br></br>
        With thousands of registered users from around the world, LegoShare is
        the go-to destination for anyone looking to expand their LEGO collection
        or connect with fellow fans. Our platform allows you to easily share
        LEGO sets and bricks with other users, all in a safe and secure
        environment.
      </div>
      <div className='ihms'>
        <Link className='ihms' to='/register'>
          <img src='/images/imhs.png' alt='Link to Register' />
        </Link>
        <img className='drawnlego4' src='/images/drawnlego4.png' alt='' />
      </div>
      <br></br>
      <br></br>
      <br></br>
      <div className='drawnlegcol'>
        <div className='drawn_leg'>
          <img src='/images/drawn_leg.png' alt='' />
        </div>
        <div className='drawn_leg2'>
          <img src='/images/drawn_lego2.png' alt='' />
        </div>
      </div>
      <div className='few'>Start Lending with few clicks</div>
      <img className='fboxcl' src='/images/fbox.png' alt='' />
      <img className='drawnlego3' src='/images/drawnlego3.png' alt='' />
      <div className='co2'>
        At LegoShare, we understand that LEGO is more than just a toy - it's a
        passion. That's why we've created a community that's as passionate about
        LEGO as you are. But that's not all - LegoShare also offers a variety of
        unique features to help you get the most out of your LEGO experience.
      </div>
      <img className='imhm' src='/images/imhmpa.png' alt='' />
      <div className='bboxb'>
        <div className='feattext'>Features</div>
        <img className='whiline' src='/images/featuresbox.png' alt='' />
        <div className='ccont123'>
          At LegoShare, we understand that LEGO is more than just a toy - it's a
          passion. That's why we've created a community that's as passionate
          about LEGO as you are. But that's not all - LegoShare also offers a
          variety of unique features to help you get the most out of your LEGO
          experience.
        </div>
      </div>
      <Footer></Footer>
    </div>
  );
}
