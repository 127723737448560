import React from 'react';
import './Styles/Subnavbar.css';

const Subnavbar = ({ onProductTypeClick }) => {
  const handleProductTypeClick = (productType) => {
    onProductTypeClick(productType);
  };

  return (
    <nav className='sub-navbar'>
      <ul>
        <li onClick={() => handleProductTypeClick('lego')}>Lego</li>
        <li onClick={() => handleProductTypeClick('marvel')}>Marvel</li>
        <li onClick={() => handleProductTypeClick('dc')}>Dc</li>
        <li onClick={() => handleProductTypeClick('kids')}>Kids</li>
        <li onClick={() => handleProductTypeClick('f1')}>F1</li>
        <li onClick={() => handleProductTypeClick('cars')}>Cars</li>
        <li onClick={() => handleProductTypeClick(null)}>More...</li>
      </ul>
    </nav>
  );
};

export default Subnavbar;
