
import React from 'react';
import './Styles/dashboard.css';
const Box1 = () => {
  return <div className='topbox1'>
             
             <svg xmlns="http://www.w3.org/2000/svg" width="35" height="8" viewBox="0 0 35 8" fill="none">
  <path d="M2.62891 1.41159C2.71176 1.99528 3.82839 2.46078 4.5019 2.7674C5.04522 3.01475 5.7983 3.35651 6.42965 3.45817C6.91776 3.53676 7.35723 3.66863 7.82071 3.7962C8.52309 3.98953 9.24072 4.0555 9.96753 4.18935C11.1873 4.414 12.3279 4.65915 13.5711 4.82133C14.7554 4.97583 15.8752 5.19055 17.0871 5.27694C18.2024 5.35645 19.2544 5.48782 20.3511 5.59293C20.7905 5.63505 21.1911 5.78509 21.6326 5.82809C22.0027 5.86413 22.388 5.83861 22.7608 5.84278C23.3603 5.84949 24.1791 5.83623 24.7652 5.92362C26.4698 6.17776 28.7239 6.29813 30.4226 5.94199C31.4838 5.71948 32.768 5.84278 33.8783 5.84278" stroke="#C6923B" stroke-linecap="round"/>
  <line x1="2.56641" y1="6.52942" x2="34.9193" y2="6.52942" stroke="#C6923B"/>
  <line x1="1.27344" y1="1" x2="1.27344" y2="7.02941" stroke="#C6923B"/>
  <path d="M4.37628 4.44689C4.09534 4.29268 3.45765 3.8755 3.78528 3.59288C4.12923 3.29619 4.97784 4.11478 5.10934 4.2677C5.30947 4.50043 4.90129 4.47812 4.63768 4.42211C4.08531 4.30476 3.61032 3.98548 3.40454 3.63101C3.28362 3.4227 3.12107 3.17395 2.88174 3.01338C2.84692 2.99001 2.32855 2.65754 2.53794 2.75412C2.75282 2.85324 2.8671 3.02959 2.83628 3.20019C2.75037 3.6757 2.73967 4.13794 2.73967 4.61846C2.73967 4.72803 2.75682 5.7866 3.07779 5.55253C3.34173 5.36006 3.48954 5.11539 3.68868 4.89677C3.89072 4.67495 4.4413 5.33572 4.50698 5.43434C4.629 5.61757 4.43055 5.44473 4.28536 5.38859C4.26177 5.37947 4.05268 5.33325 4.12056 5.34284C4.95787 5.46111 5.86001 5.38925 6.70333 5.35428C7.5877 5.3176 8.48943 5.24635 9.37702 5.27803C9.81666 5.29372 10.9951 5.57723 9.74639 5.2094C9.33164 5.08723 8.03971 4.71256 7.63244 4.59558C7.41122 4.53204 7.18982 4.46876 6.96757 4.40686C6.88319 4.38336 6.79383 4.3231 6.70901 4.34586C6.69442 4.34977 7.09448 4.50678 7.121 4.51552C7.5006 4.64062 7.92782 4.721 8.28595 4.8739C8.39331 4.91974 8.03047 4.86994 7.90521 4.85483C6.74291 4.71472 5.58896 4.54396 4.42742 4.40114C4.20527 4.37383 3.9756 4.34395 3.74835 4.34395C3.39259 4.34395 4.44349 4.44573 4.79111 4.49646C5.86025 4.65247 6.92641 4.81163 7.99045 4.98256C8.29206 5.03101 8.63252 5.03845 8.9224 5.11409C9.25381 5.20055 8.73509 5.12303 8.65816 5.11028C8.239 5.0408 7.8099 4.97337 7.37672 4.96159C7.01965 4.95187 7.53501 4.99713 7.59834 5.00734C9.04094 5.23979 10.4202 5.61353 11.92 5.61353C12.0271 5.61353 11.7058 5.61077 11.5989 5.60591C11.0587 5.58133 10.5215 5.5186 9.99643 5.43053C9.90926 5.41591 9.65085 5.37334 9.74071 5.37334C10.2947 5.37334 10.8458 5.49035 11.3887 5.54872C12.4945 5.66761 13.617 5.64784 14.7329 5.64784C15.0644 5.64784 15.3886 5.73711 15.7217 5.74888C15.9885 5.7583 16.2586 5.75078 16.5258 5.75078C17.0178 5.75078 17.4402 5.85372 17.9294 5.85372" stroke="#C6923B" stroke-linecap="round"/>
</svg>

  </div>
  };

  export default Box1;
  