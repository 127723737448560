import axios from 'axios';
import { getUserData } from './storage';

axios.defaults.baseURL = 'https://identitytoolkit.googleapis.com/v1';
const API_KEY = 'AIzaSyCMHHJ152wi4bzQ-yKc2_mhwXT14As0Ivg';
const REGISTER_URL = `/accounts:signUp?key=${API_KEY}`;
const LOGIN_URL = `/accounts:signInWithPassword?key=${API_KEY}`;
const USER_DETAILS_URL = `/accounts:lookup?key=${API_KEY}`;

export const RegisterApi = (inputs) => {
  let data = {
    displayName: inputs.name,
    email: inputs.email,
    password: inputs.password,
    dob: inputs.dob,
    ssn: inputs.ssn,
    address1: inputs.address1,
    address2: inputs.address2,
    postal: inputs.postal,
    state: inputs.state,
    country: inputs.country,
  };
  return axios.post(REGISTER_URL, data);
};
export const LoginApi = (inputs) => {
  let data = { email: inputs.email, password: inputs.password };
  return axios.post(LOGIN_URL, data);
};
export const UserDetailsApi = () => {
  let data = { idToken: getUserData() };
  return axios.post(USER_DETAILS_URL, data);
};
