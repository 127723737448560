import React from "react";
import { Link } from "react-router-dom";
import './Styles/OrderSuccess.css';

const OrderSuccess = () => {
  return (
    <div className="order-success">
      <div className="containerorder" id="odd">
      <h1>Order placed successfully!</h1>
      <p>Check your order in dashboard.</p>
      <Link to="/dashboard" className="dashboard-button">Dashboard</Link>
    </div>
    </div>
  );
}

export default OrderSuccess;
