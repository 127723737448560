import './Styles/signin.css';
import NavBar from '../components/NavBar';
import Footer from './Footer';
import { useState } from 'react';
import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
  OAuthProvider,
} from 'firebase/auth';
import { storeUserData } from '../services/storage';
import { isAuthenticated } from '../services/Auth';
import { Link, Navigate } from 'react-router-dom';
import MetaData from './MetaDatas';

export default function SignIn() {
  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    custom_error: null,
  };
  const [errors, setErrors] = useState(initialStateErrors);
  const [loading, setLoading] = useState(false);
  const [inputs, setInputs] = useState({
    email: '',
    password: '',
  });
  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };
  const handleGoogleSignIn = async () => {
    setLoading(true);

    const auth = getAuth();
    const provider = new GoogleAuthProvider();

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      storeUserData(user.accessToken);
    } catch (error) {
      console.error('Google authentication error:', error);
      setErrors({
        ...errors,
        custom_error: 'Failed to sign in with Google. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleMicrosoftSignIn = async () => {
    setLoading(true);

    const auth = getAuth();
    const provider = new OAuthProvider('microsoft.com');

    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      storeUserData(user.accessToken);
    } catch (error) {
      console.error('Microsoft authentication error:', error);
      setErrors({
        ...errors,
        custom_error: 'Failed to sign in with Microsoft. Please try again.',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;
    if (inputs.email === '') {
      errors.email.required = true;
      hasError = true;
    }
    if (inputs.password === '') {
      errors.password.required = true;
      hasError = true;
    }
    if (!hasError) {
      setLoading(true);
      const auth = getAuth();
      signInWithEmailAndPassword(auth, inputs.email, inputs.password)
        .then((userCredential) => {
          const user = userCredential.user;
          storeUserData(user.accessToken);
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          if (
            errorCode === 'auth/user-not-found' ||
            errorCode === 'auth/wrong-password'
          ) {
            setErrors({
              ...errors,
              custom_error: 'Invalid email or password.',
            });
          } else {
            setErrors({
              ...errors,
              custom_error: errorMessage || 'Oops! Something went wrong.',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    setErrors({ ...errors });
  };
  if (isAuthenticated()) {
    return <Navigate to='/dashboard' />;
  }
  return (
    <div className='Signin'>
      <NavBar />
      <MetaData title={'SignIn'} />
      <br></br>
      <br></br>
      <img className='signtop' alt='Elementfooter' src='./images/signtop.png' />
      <section className='login-block'>
        <h2 className='text-center'>Sign In</h2>
        <h3 className='rectagles'></h3>
        <form onSubmit={handleSubmit} className='login-form' action=''>
          {/* <div className='form-group'>
            <label htmlFor='exampleInputEmail1' className='text-uppercase'>
              Email
            </label>
            <input
              type='email'
              className='form-control'
              onChange={handleInput}
              name='email'
              id='emmail'
              placeholder='email'
            />
            {errors.email.required ? (
              <span className='text-danger'>Email is required.</span>
            ) : null}
          </div>
          <div className='form-group'>
            <label htmlFor='exampleInputPassword1' className='text-uppercase'>
              Password
            </label>
            <input
              className='form-control'
              type='password'
              onChange={handleInput}
              name='password'
              placeholder='password'
              id='password'
            />
            {errors.password.required ? (
              <span className='text-danger'>Password is required.</span>
            ) : null}
          </div>
          <div className='form-group'>
            {loading ? (
              <div className='text-center'>
                <div className='spinner-border text-primary ' role='status'>
                  <span className='sr-only'>Loading...</span>
                </div>
              </div>
            ) : null}
            <span className='text-danger'>
              {errors.custom_error ? <p>{errors.custom_error}</p> : null}
            </span>
            <input
              type='submit'
              className='btn-signin'
              disabled={loading}
              value='Login'
            />
          </div>*/}
          <div onClick={handleGoogleSignIn} className='btngoogle'>
            <img src='./images/continuewithgoogle.png' alt='Google Sign In' />
          </div>
          <br></br>
          <div onClick={handleMicrosoftSignIn} className='btngoogle'>
            <img src='./images/btn-mic.png' alt='Google Sign In' />
          </div>
          <br></br>
          <div className='clearfix'></div>
          <div className='form-group'>
            Create a new account? Please <Link to='/register'>Register</Link>
          </div>
          <br />
        </form>
      </section>
      <br></br>
      <img
        className='elementfooter'
        alt='Elementfooter'
        src='./images/signinfooter.png'
      />
    </div>
  );
}
