import React, { useState } from 'react';
import './Styles/SignUp.css';
import MetaData from './MetaDatas';
import '@fortawesome/fontawesome-free/css/all.css';
import NavBar from '../components/NavBar';
import { isAuthenticated } from '../services/Auth';
import { RegisterApi } from '../services/api';
import { storeUserData } from '../services/storage';
import { Link, Navigate } from 'react-router-dom';

function ChildSignup() {
  const initialStateErrors = {
    email: { required: false },
    password: { required: false },
    name: { required: false },
    lastname: { required: false },
    dob: { required: false },
    ssn: { required: false },
    address1: { required: false },
    address2: { required: false },
    postal: { required: false },
    state: { required: false },
    country: { required: false },
    confirmpassword: { required: false },
    custom_error: null,
  };
  const [errors, setErrors] = useState(initialStateErrors);

  const [loading, setLoading] = useState(false);

  const handleSubmit = (event) => {
    event.preventDefault();
    let errors = initialStateErrors;
    let hasError = false;
    if (inputs.name == '') {
      errors.name.required = true;
      hasError = true;
    }
    if (inputs.lastname == '') {
      errors.lastname.required = true;
      hasError = true;
    }
    if (inputs.email == '') {
      errors.email.required = true;
      hasError = true;
    }
    if (inputs.password == '') {
      errors.password.required = true;
      hasError = true;
    }
    if (inputs.ssn === '') {
      errors.ssn.required = true;
      hasError = true;
    }

    if (inputs.dob === '') {
      errors.dob.required = true;
      hasError = true;
    }

    if (inputs.address1 === '') {
      errors.address1.required = true;
      hasError = true;
    }

    if (inputs.address2 === '') {
      errors.address2.required = true;
      hasError = true;
    }

    if (inputs.country === '') {
      errors.country.required = true;
      hasError = true;
    }

    if (inputs.state === '') {
      errors.state.required = true;
      hasError = true;
    }

    if (inputs.postal === '') {
      errors.postal.required = true;
      hasError = true;
    }

    if (!hasError) {
      setLoading(true);
      RegisterApi(inputs)
        .then((response) => {
          storeUserData(response.data.idToken);
        })
        .catch((err) => {
          if (err.response.data.error.message == 'EMAIL_EXISTS') {
            setErrors({
              ...errors,
              custom_error: 'Already this email has been registered!',
            });
          } else if (
            String(err.response.data.error.message).includes('WEAK_PASSWORD')
          ) {
            setErrors({
              ...errors,
              custom_error: 'Password should be at least 6 characters!',
            });
          }
        })
        .finally(() => {
          setLoading(false);
        });
    }
    console.log(initialStateErrors, errors);
    setErrors(errors);
  };

  const [inputs, setInputs] = useState({
    email: '',
    password: '',
    name: '',
    lastname: '',
    dob: '',
    ssn: '',
    address1: '',
    address2: '',
    postal: '',
    state: '',
    country: '',
    confirmpassword: '',
  });

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const togglePasswordVisibility = (field) => {
    if (field === 'password') {
      setShowPassword((prevShowPassword) => !prevShowPassword);
    } else if (field === 'confirmPassword') {
      setShowConfirmPassword(
        (prevShowConfirmPassword) => !prevShowConfirmPassword
      );
    }
  };
  const handleInput = (event) => {
    setInputs({ ...inputs, [event.target.name]: event.target.value });
  };

  if (isAuthenticated()) {
    return <Navigate to='/dashboard' />;
  }

  return (
    <div>
      <NavBar />
      <MetaData title={'SignUp'} />
      <div className='register-block'>
        <div className='container'>
          <div className='row '>
            <div className='col register-sec'>
              <h2 className='text-center'>Child Register Now</h2>
              <form onSubmit={handleSubmit} className='register-form' action=''>
                <div className='form-groupname'>
                  <label htmlFor='firstName' className='text-uppercase'>
                    First Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='name'
                    id='firstName'
                  />
                  {errors.name.required == true ? (
                    <span className='text-danger'>First Name is required.</span>
                  ) : null}
                </div>
                &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                <div className='form-groupname'>
                  <label htmlFor='lastName' className='text-uppercase'>
                    Last Name
                  </label>
                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='lastname'
                    id='lastName'
                  />
                  {errors.lastname.required == true ? (
                    <span className='text-danger'>Last Name is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Date of Birth
                  </label>

                  <input
                    type='date'
                    className='form-control'
                    onChange={handleInput}
                    name='dob'
                    id=''
                  />
                  {errors.dob.required == true ? (
                    <span className='text-danger'>DOB is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Parent ID
                  </label>

                  <input
                    type='number'
                    className='form-control'
                    onChange={handleInput}
                    name='parent id'
                    id=''
                  />
                  {errors.dob.required == true ? (
                    <span className='text-danger'>Parent ID is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Social Security Number
                  </label>

                  <input
                    type='number'
                    className='form-control'
                    onChange={handleInput}
                    name='ssn'
                    id=''
                  />
                  {errors.ssn.required === true ? (
                    <span className='text-danger'>SSN is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Email
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='email'
                    id=''
                  />
                  {errors.email.required == true ? (
                    <span className='text-danger'>Email is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Address Line 1
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='address1'
                    id=''
                  />
                  {errors.address1.required == true ? (
                    <span className='text-danger'>
                      Address Line 1 is required.
                    </span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Address Line 2
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='address2'
                    id=''
                  />
                  {errors.address2.required == true ? (
                    <span className='text-danger'>
                      Address Line 2 is required.
                    </span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Postal Code
                  </label>

                  <input
                    type='number'
                    className='form-control'
                    min='6'
                    max='10'
                    onChange={handleInput}
                    name='postal'
                    id=''
                  />
                  {errors.postal.required == true ? (
                    <span className='text-danger'>
                      Postal Code is required.
                    </span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    State
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='state'
                    id=''
                  />
                  {errors.state.required == true ? (
                    <span className='text-danger'>state is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputEmail1'
                    className='text-uppercase'
                  >
                    Country
                  </label>

                  <input
                    type='text'
                    className='form-control'
                    onChange={handleInput}
                    name='country'
                    id=''
                  />
                  {errors.country.required == true ? (
                    <span className='text-danger'>Country is required.</span>
                  ) : null}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputPassword1'
                    className='text-uppercase'
                  >
                    Password
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type={showPassword ? 'text' : 'password'}
                      onChange={handleInput}
                      name='password'
                      id=''
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        onClick={() => togglePasswordVisibility('password')}
                      >
                        <i
                          className={`fas ${
                            showPassword ? 'fa-eye-slash' : 'fa-eye'
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                  {errors.password.required && (
                    <span className='text-danger'>Password is required.</span>
                  )}
                </div>
                <div className='form-group'>
                  <label
                    htmlFor='exampleInputPassword1'
                    className='text-uppercase'
                  >
                    Confirm Password
                  </label>
                  <div className='input-group'>
                    <input
                      className='form-control'
                      type={showConfirmPassword ? 'text' : 'password'}
                      onChange={handleInput}
                      name='confirmPassword'
                      id=''
                    />
                    <div className='input-group-append'>
                      <button
                        className='btn btn-outline-secondary'
                        type='button'
                        onClick={() =>
                          togglePasswordVisibility('confirmPassword')
                        }
                      >
                        <i
                          className={`fas ${
                            showConfirmPassword ? 'fa-eye-slash' : 'fa-eye'
                          }`}
                        />
                      </button>
                    </div>
                  </div>
                  {errors.confirmpassword.required && (
                    <span className='text-danger'>
                      Confirm Password is required.
                    </span>
                  )}
                </div>
                <div className='form-group'>
                  <span className='text-danger'>
                    {errors.custom_error == true ? (
                      <p>{errors.custom_error}</p>
                    ) : null}
                  </span>
                  {loading ? (
                    <div className='text-center'>
                      <div
                        className='spinner-border text-primary '
                        role='status'
                      >
                        <span className='sr-only'>Loading...</span>
                      </div>
                    </div>
                  ) : null}

                  <input
                    type='submit'
                    className='btn btn-login float-right'
                    disabled={loading}
                    value='Register'
                  />
                </div>
                <div className='clearfix'></div>
                <div className='form-group'>
                  Already have an account ? Please{' '}
                  <Link to='/login'>Login</Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChildSignup;
